.c-shared-extend-rfq {
  padding: 0 20px;

  .MuiFormControl-root {
    width: 100%;
    margin-top: 5px;

    label {
      color: black;
    }
  }

  .MuiInputBase-root {
    border: 1px solid black;
    border-radius: 4px;
    padding: 10px;
    margin-top: 35px;
  }

  .c-rfq-action.MuiDialogActions-root {
    padding-right: 0;
    padding-top: 48px;
  }
}
