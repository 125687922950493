.c-add-more-po-files {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  .c-add-more-po-files--attachments {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 20%;
  }

  .c-add-more-po-files--uploadSvg {
    margin-top: 5%;
  }

  .c-add-more-po-files--btn {
    width: 20%;
    margin-left: 55%;
    margin-right: 25%;
    margin-top: 20%;
  }

  .c-buyer-nda-upload--btn {
    width: 100%;
    margin-top: 20%;
  }

  .drop-zone-wrapper {
    .drop-zone .dropzone-content {
      margin: 0;
    }
  }
}
