.c-filter-part-tags {
  width: 250px;

  .MuiInputBase-root.MuiOutlinedInput-root {
    height: 43px;
    padding-left: 14px;
  }

  .MuiInputLabel-root {
    text-transform: uppercase;
    margin-top: -5px;

    &.Mui-focused {
      color: black;
      text-transform: capitalize;
    }
  }

  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: black;
    }
  }

  .MuiAutocomplete-inputRoot {
    text-transform: uppercase;
    font-size: 14px;
    color: #101326;
    padding-left: 14px;
  }

  #checkboxes-tags-demo {
    position: absolute;
    width: 85%;
    height: 65%;
  }
}

.MuiAutocomplete-popper {
  #checkboxes-tags-demo-popup {
    li {
      margin-bottom: 0;
      padding-left: 4px;
    }
  }
}
