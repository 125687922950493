.c-details-summary-header {
  .c-cancellation-reason {
    background-color: #ededed;
    width: 100%;
    height: 84px;
    padding: 0 10px;

    .c-cancellation-reason--button {
      text-transform: uppercase;
      color: black;

      &.MuiButton-contained {
        padding: 10px 16px;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
    .c-cancellation-reason--menu {
      &.MuiList-padding {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      .reason-box {
        word-break: break-word;
        white-space: break-spaces;
      }
    }
  }

  .c-details-summary-header--quotation-name {
    color: #000;
    opacity: 0.87;

    .c-span-rfq-name {
      margin-left: -10px;
    }

    .lock-open-icon {
      height: 19px;
      width: 19px;
      color: $color-success;
      margin-right: 8px;
      margin-left: -10px;
      margin-top: -6px;
    }

    .lock-icon {
      height: 19px;
      width: 19px;
      color: black;
      margin-right: 8px;
      margin-left: -10px;
      margin-top: -6px;
    }

    .lock-icon-grey {
      height: 19px;
      width: 19px;
      margin-right: 8px;
      margin-top: -6px;
      margin-left: -10px;
      color: $color-grey;
    }
  }

  .info-icon {
    &.MuiSvgIcon-root {
      color: $color-grey-600;
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }
  }

  .c-details-summary-header--tag-name {
    margin-left: 10px;
  }

  .c-details-summary-header--chip {
    font-weight: normal;
    font-size: 16;
  }

  .c-details-summary-header--access-time-icon {
    width: 20px;
    height: 20px;
    margin: 0 2px;
    color: $color-grey-500;
  }

  .c-details-summary-header--additional-details {
    word-break: break-word;
    white-space: break-spaces;
  }
}

.card-info-container {
  width: 100%;
}
