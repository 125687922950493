.c-projects-table {
  display: flex;
  height: 100%;

  &__container {
    flex-grow: 1;
    min-height: 500px;
  }

  &__customLink {
    color: black;

    &:hover {
      color: black;
    }
  }
}
