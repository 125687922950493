.c-part-tags {
  .c-part-field-box {
    margin-top: 12px;
    display: flex;

    .c-part-field-name {
      font-weight: normal;
      color: black;
      opacity: 0.8;
      text-align: left;
      flex-grow: 1;
    }

    .c-part-field-value {
      text-align: end;
      color: $color-text-medium;
      font-weight: 500;
    }
  }
}
