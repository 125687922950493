.c-part-name {
  color: #000;
  opacity: 0.87;
  word-break: break-word;
}

.blurred-content {
  filter: blur(7px);
  pointer-events: none;
  width: 79px;
  background: #a3a3a3;
  position: relative;
  display: inline-block;
  height: 7px;
}

.c-offers-view {
  border: 1px solid $border-color-secondary;

  & + .c-offers-view {
    border-top: none;
  }

  &.Mui-expanded + .c-offers-view,
  & + .c-offers-view.Mui-expanded,
  .MuiCollapse-container {
    border-top: 1px solid $border-color-secondary;
  }

  &.MuiExpansionPanel-root:before {
    height: 0;
  }
}

.c-offers-view--panel-header.MuiExpansionPanelSummary-root {
  padding: 12px 24px;
}

.disabled-expansion-panel {
  pointer-events: none;
}

.c-offers-view--additional-details {
  color: black;
  opacity: 0.87;
  word-break: break-word;
  white-space: break-spaces;
}

.c-offer-details {
  &__commissionFees {
    display: flex;
    font-size: 16px;
    align-items: center;
    color: black;
    opacity: 0.87;
    .s-span {
      display: flex;
      align-items: center;
      margin-left: 4px;

      &__avatar {
        width: 23px;
        height: 23px;
        margin-left: 4px;
      }
    }
  }
}

.c-offer-summary {
  .c-offer-summary--avatar {
    pointer-events: all;
    width: 80px;
    height: 80px;
  }

  .c-offer-summary--company-name {
    pointer-events: all;
    opacity: 1;
  }

  .c-offer-summary--boxLabel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .commissionFeeLabel-color {
    color: $color-text-light;
  }

  .c-offer-summary--full-profile {
    .c-offer-summary--arrow-forward-icon {
      width: 12px;
      height: 12px;
      color: #2680eb;
    }
  }
  .c-offer-summary--full-profile:hover {
    text-decoration: underline;
  }
  .MuiRating-root {
    color: $color-primary;
    height: 11px;

    .MuiSvgIcon-root {
      width: 11px;
      height: 11px;
    }
  }

  @media screen and (max-width: 1280px) {
    .c-offer-summary--lead-time {
      justify-content: flex-start;
      margin-top: 16px;
    }
  }
}
