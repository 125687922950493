.c-user-info {
  margin-left: 24px;
  .c-user-info--created-time {
    color: $color-text-light;
    font-size: 12px;
  }

  .c-user-info--company-name {
    @extend .c-user-info--created-time;
    display: block;
  }

  .c-user-info--author-info {
    color: "black";

    .MuiAvatar-circle {
      width: 32px;
      height: 32px;
      margin-top: 3px;
    }
  }
}

.c-message {
  margin: 0 16px 0 64px;
  .box-cursor-pointer {
    cursor: pointer;
  }

  .c-message--file-name {
    background: #101326;
    height: 38px;
    color: white;
    border-bottom: 1px solid #2d3040;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .border-radius {
    border-radius: 4px;
  }

  .c-message--chip {
    height: 22px;
    width: 46px;
    font-size: 12px;
    color: white;
    border: white;
    background: #ffffff1f;
  }

  .c-message--link {
    color: white;
  }

  .c-message--open-in-new-icon {
    width: 15px;
    height: 15px;
  }

  .c-message--typography {
    overflow-wrap: break-word;
    white-space: pre-wrap;
    color: $color-text-light;
    font-size: 14px;
    word-break: break-word;
    line-height: 20px;
  }
}

.c-discussion--content .c-quote {
  padding: 0;
  position: relative;

  & > p {
    align-self: center;
    margin: 0;
    color: $color-text-light;
    font-size: 12px;

    &:before,
    &:after {
      content: "";
      width: 40%;
      height: 1px;
      background: $border-color-secondary;
      position: absolute;
      top: 8px;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
}

.c-message--quotation-details {
  border: 1px solid $border-color-secondary;
  background: white;
  color: black;
  list-style: none;
  margin: 8px 0;
  border-radius: 8px;
  z-index: 0;

  li {
    padding: 12px;
    border-bottom: 1px solid $border-color-secondary;
    display: flex;
    justify-content: space-between;
    margin: 0;

    &:last-child {
      border: none;
    }

    .c-message--part-quantity {
      color: $color-text-light;
      font-size: 12px;
    }
  }
}

.c-message--file-download-link {
  cursor: pointer;
}

.c-message--attachments {
  border: 1px solid $border-color-secondary;
  padding: 16px;
  border-radius: 4px;
  color: black;
  margin-top: 4px;
  margin-bottom: 4px;

  &:hover {
    background-color: #eeeeee;
  }

  & > span {
    padding-right: 10px;
  }

  & > a {
    svg {
      font-size: 22px;
    }
  }
}

.c-quotation-update-message {
  border: 1px solid $border-color-secondary;
  background: white;
  color: black;
  list-style: none;
  margin: 8px 0;
  border-radius: 8px;
  .update-message-table-row {
    word-break: break-word;
    .MuiTableCell-sizeSmall {
      padding: 6px 8px 6px 8px;
    }
  }

  .download-link {
    color: $color-primary;
    cursor: pointer;
  }
  .download-link:hover {
    text-decoration: underline;
  }
}

.update-message-additional-details {
  border: 1px solid $border-color-secondary;
  border-top: none;
  background: white;
  color: black;
  list-style: none;
  margin-top: -8px;
  border-radius: 8px;
  .MuiTableCell-sizeSmall {
    padding: 6px 8px 6px 8px;
  }
}
.update-message-additional-details-border-top {
  border: 1px solid $border-color-secondary;
  background: white;
  color: black;
  list-style: none;
  margin-top: -8px;
  border-radius: 8px;
  .MuiTableCell-sizeSmall {
    padding: 6px 8px 6px 8px;
  }
}
