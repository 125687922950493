@import "../rfq-NDA/rfq-NDA.scss";
@import "../../user-onboarding/user-onboarding-stepper/user-onboarding-stepper.scss";

.c-rfq-stepper {
  .c-rfq-steps {
    &.c-rfq-general-information--form {
      margin-left: 260px;
    }
  }
}
