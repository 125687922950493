.c-rfq-part-summary {
  display: flex;
  border: 1px solid $color-grey;
  border-radius: 5px;
  margin-top: 40px;

  .c-rfq-part-summary--container {
    display: flex;
    padding-top: 12%;
    padding-left: 55px;

    .c-rfq-part-summary--typography {
      margin-left: 200px;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
