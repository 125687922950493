@import "./rfq-part-summary/rfq-part-summary.scss";

.c-rfq-summary {
  margin: 2% 10% 0;
  .c-rfq-summary--tag {
    margin-left: 10px;

    .c-rfq-summary--chip {
      font-weight: normal;
      font-size: 16px;
    }
  }

  .c-rfq-summary--additional-details {
    font-family: $font-family;
    white-space: break-spaces;
    word-break: break-word;
  }

  .c-rfq-summary--btn-container {
    margin-top: 50px;
    margin-bottom: 50px;

    .c-rfq-summary--color-black {
      color: black;
    }
    .c-rfq-summary--color-white {
      color: white;
    }
  }
  .c-rfq-summary--title {
    margin-top: 100px;
  }
  .c-rfq-summary--table-container {
    margin-top: 25px;
    .c-rfq-summary--table-row {
      border-top: 1px solid $color-grey;
      .c-rfq-name {
        word-break: break-word;
      }
    }
  }

  .c-slider {
    width: 140px;
    margin: auto;

    .carousel-slider {
      .slider-wrapper {
        width: 75%;
        border: 1px solid $color-part-thumbs-border;
        border-radius: 4px;

        .slide {
          background: rgb(254, 254, 254);
          background-image: linear-gradient(
            rgba(254, 254, 254, 1) 0%,
            rgba(241, 241, 241, 1) 24%,
            rgb(206, 206, 206) 100%
          );

          img {
            height: 88px;
            object-fit: cover;
          }
        }
      }
    }

    .control-arrow {
      opacity: 1;
      color: $color-part-thumbs-arrow;
      background: none !important;
      top: -5px;

      &:before {
        display: none;
      }

      &.control-prev {
        left: -12px;
      }

      &.control-next {
        right: -12px;
      }
    }
  }

  .c-rfq-summary--suppliers-list {
    margin: 48px 0;

    h5 {
      border-bottom: 1px solid #d5d5d5;
      padding-bottom: 12px;
      margin-bottom: 20px;
    }

    .MuiChip-root {
      background-color: #eeeeee;
      font-size: 16px;
      margin: 4px;

      .MuiChip-label {
        padding: 16px;
      }
    }
  }
}
