@import "./projects.scss";
@import "./project-main.scss";
@import "./project-not-found.scss";

.MuiDataGrid-columnHeader,
.MuiDataGrid-columnHeaderWrapper {
  background: #f5f5f5;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}
