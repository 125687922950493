@import "./filter-active-rfq-list/filter-active-rfq-list.scss";

.c-marketplace-active-rfqs {
  flex-grow: 1;
  .on-click-active-rfq {
    cursor: pointer;
  }
  .on-click-active-rfq:hover {
    .c-supplier-rfq-list-hover-buttons--icon {
      visibility: visible !important;
    }
  }

  .part-number {
    margin-bottom: 3px;
  }

  .part-row {
    width: 80px;
  }

  .c-number-of-bids {
    color: $color-text-light;
  }

  .no-bids-container {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .no-bids-div {
      margin-bottom: 8px;
    }

    .no-bids-message {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      height: 475px;
      align-items: center;
      flex-direction: column;
      .c-no-bids--support-text {
        margin-top: 5px;
        color: #9e9e9e;
      }
    }
  }
}
