.part-details {
  width: 100%;
  display: flex;

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }

  .part-details--close-icon {
    cursor: pointer;
  }

  .part-infos--divider {
    &.MuiDivider-root {
      width: 108%;
      margin-left: -16px;
      margin-top: 12px;
    }
  }

  .part-details--comment-section {
    margin-top: -4px;
    margin-bottom: 12px;
  }

  .viewer-container {
    width: 70%;
    border-right: 1px solid grey;
    .card-media {
      width: 100%;
    }
  }

  .part-infos-container {
    width: 30%;
    overflow-y: auto;

    .additional-details-container {
      margin-top: 12px;
      word-break: break-word;
      white-space: break-spaces;
    }

    .list-upload-container {
      width: 100%;
      margin-top: 12px;
    }

    .details-text {
      color: #000;
      opacity: 0.87;
      word-break: break-word;
      white-space: break-spaces;
      margin-top: 4px;
    }

    .part-infos-title {
      display: flex;
      justify-content: space-between;
    }
  }
}
