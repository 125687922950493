.c-supplier-nda {
  margin: 32px auto;
  max-width: 80%;

  & > div {
    min-height: 60vh;
  }

  .c-stepper {
    .c-stepper--container {
      margin-left: 0;

      & > .MuiGrid-root {
        margin: auto;
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
}

.c-nda-signature {
  a.c-nda-link {
    color: $color-primary;
    text-decoration: underline;
    cursor: pointer;
  }
}
