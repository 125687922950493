.c-buyer-rfq-view {
  margin-top: -40px;

  .no-bids-div {
    width: 100;
    justify-content: center;
    display: flex;
  }

  .no-bids-container {
    margin-bottom: 30px;
  }

  .root {
    flex-grow: 1;
    width: "90%";
    margin: "0 5% 0 5% ";
  }

  .appBar {
    background-color: #ffffff !important;
    color: "black";
    padding: 0;
  }

  &__toolbar.MuiToolbar-regular {
    width: 40%;
    padding-left: 0;

    &__back-button {
      margin: 0 0 10px 0;
    }
  }

  &__container {
    &__divider {
      position: absolute;
      left: 0;
      right: 0;
    }

    &__tag {
      margin-left: 10px;
    }
    .c-box {
      color: black;
      opacity: 0.87;
    }

    &__chip {
      font-size: 16px;
    }
  }
}
