.c-company-stats {
  margin: 18px;
  .MuiRating-root {
    color: $color-primary;
    height: 11px;

    .MuiSvgIcon-root {
      width: 13px;
      height: 13px;
    }
  }

  .c-seller-company-stats {
    .overall-score {
      color: $color-primary;
      font-weight: bold;
    }
    .rating-score {
      font-weight: lighter;
    }
  }
}
