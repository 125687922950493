.part-form {
  height: 100%;
  position: relative;
  background: white;

  .thumb-wrapper {
    width: 78px;
    max-height: 58px;
    margin-right: 8px;
    background: white;
    border: 1px solid black;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    img.part-thumb {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(#fefefe 0%, #f1f1f1 24%, #cecece 100%);
    }
  }

  .error {
    color: red;
    padding: 5px;
  }

  .text-field {
    flex: 1;
  }
}
