.c-part-tile {
  .c-part-tile--container {
    text-align: center;
    display: flex;
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 0;
    margin-top: 26px;

    .MuiCardContent-root:last-child {
      padding-bottom: 0;
    }
  }

  .c-part-tile--card {
    width: 50%;
  }

  .c-part-tile--price-container {
    padding: 0;
    width: 50%;
  }

  .c-part-tile--quantity {
    width: 100%;
    border-bottom: solid 1px $color-grey;
  }

  .c-part-tile--card--flex-grow {
    flex-grow: 1;
    width: 100%;
  }

  @media screen and (max-width: 1140px) {
    .c-part-tile--container {
      flex-direction: column;
    }

    .c-part-tile--price-container {
      width: 100%;
    }

    .c-part-tile--quantity,
    .c-part-tile--card--flex-grow {
      padding-left: 24px;
    }
    .c-part-tile--card {
      width: 100%;
    }
  }
}
