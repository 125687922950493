/* colors */
$color-primary: #2682fd;
$color-success: #4caf50;
$color-grey: #bdbdbd;
$color-grey-700: #616161;
$color-grey-500: #9e9e9e;
$color-grey-600: #9f9f9f;
$color-grey-700: #eeeeee;
$color-grey-100: #f5f5f5;
$color-grey-50: #d5d5d5;
$color-grey-800: #747474;
$color-grey-svg: #c4c4c4;
$color-text-medium: #000000de;
$color-text-light: #00000099;
$border-color: #5a5a5a;
$border-color-black-1f: #0000001f;
$border-color-secondary: #e0e0e0;
$border-color-primary: #d6d6d6;
$border-color-grey: #d3d3d3;
$border-color-light-grey: #0000001f;
$profile-text-color: #000000de;
$profile-line-color: #00000029;
$helper_text_background_color: #f1f1f1;
$color-checkbox-active: #e521391f;
$color-background-cover: #f7f7f7;
$unnamed-color: #000000de;
$color-box-shadow: #00000033;
$color-background-button-active: #e521391f;
$color-background-step: #fafafa;

$contact_background_color: #e5e5e5;
$modal-banner-background-color: #ffcdd2;

$color-part-thumbs-border: #707070;
$color-part-thumbs-arrow: #b9b9b9;

$table-header-bg-color: #f8f8f8;

/* fonts */
$font-preferred: "Roboto";
$font-system: "sans-serif";
$font-family: $font-preferred, $font-system;

$font-weight-bold: 700;
$font-weight-normal: 600;
$font-weight-light: 300;

$font-size-root: 16px;
$font-size-18: 18px;
$font-size-14: 14px;
