.c-intent-to-bid-section {
  .c-intent-to-bid {
    .c-intent-to-bid--text {
      padding: 8px;
      color: $color-text-medium;
    }
  }

  .c-intent-to-bid--actions {
    padding: 8px 0;

    .c-intent-to-bid--clear-date {
      margin-top: 34px;
      margin-right: 8px;
    }

    .c-intent-to-bid--submit-date {
      margin-top: 34px;
    }
  }
}
