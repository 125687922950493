.new-parts {
  width: 100%;

  .c-workspace-parts--card-container {
    display: flex;
    flex-wrap: wrap;
  }

  .c-workspace-cards--none-selected {
    color: $color-text-light;
  }

  .c-workspace-cards--selected,
  .c-workspace-cards--none-selected {
    text-align: right;
    margin-right: 16px;
  }
}

.no-part-img {
  width: 230px;
}

.no-part-text {
  margin: 0 auto;
  color: $color-text-medium;

  .no-part-text--space {
    margin-top: 28px;
  }
}

.no-part-btn {
  margin: 16px auto 0 auto;

  .MuiButton-root {
    text-transform: capitalize;
  }
}

.selected-tags {
  margin: 0 12px 0 0;
}

.selected-tags-chips {
  margin-left: 8px;
}

.c-loader {
  .c-workspace-loader {
    margin-top: 15%;
  }
}

.c-empty-workspace--grid {
  text-align: center;
  margin: 0 auto;
  height: 50vh;
}
