.part-attachments {
  .MuiListItem-container {
    margin-bottom: 0;
  }
  overflow: scroll;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  &.MuiList-padding {
    padding-top: 0;
  }
  .MuiListItemText-inset {
    padding-left: 16px;
  }

  .MuiTypography-body1 {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .file-upload {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom-push {
    position: fixed;
    bottom: 0;
    text-align: center;
    padding-bottom: 10px;
    height: 100px;
  }
}
.list-style-none {
  list-style: none;
}
.po-attachments {
  .color-item {
    color: $color-primary;
    text-decoration: underline;
    padding-left: 16px;

    & > span {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .background-transparent {
    border-bottom: 1px solid $profile-line-color;
    border-top: 1px solid $profile-line-color;
    background: transparent !important;
    padding: 24px 0;
    pointer-events: none;
  }

  .remove-attachment-icon {
    right: 5px;
    cursor: pointer;
    z-index: 1;
  }
}

.c-visibility-detection {
  .part-attachments {
    height: 0;
  }
}
