.c-marketplace-tabs {
  .MuiTab-fullWidth {
    max-width: fit-content;
    min-width: max-content;
  }

  .MuiTabs-indicator {
    height: 4px;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: black;
    font-weight: 500;
  }
}
