.c-user-company-information {
  background: $color-background-step;
  height: 100vh;
  overflow: auto;

  .c-user-company-information--container {
    width: 440px;
    padding-bottom: 48px;
    padding-top: 40px;
  }

  .box-description {
    border-bottom: 2px solid $color-grey-50;
  }

  .address-box-width {
    width: 212px;
  }

  .phone-number {
    .MuiOutlinedInput-colorSecondary.Mui-focused
      .MuiOutlinedInput-notchedOutline {
      border-color: black;
    }
    .MuiFormLabel-colorSecondary.Mui-focused {
      color: black;
    }
  }

  .stepper-button-icon {
    width: 10px;
    height: 10px;
  }

  .button-progress-container {
    position: relative;
    .button-progress {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
      color: $color-primary;
    }
  }
}
