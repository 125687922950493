.c-rfq-nda--info.MuiAlert-root {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px 14%;

  .MuiAlert-message {
    width: 55%;

    p {
      margin: 0;
    }
  }
}

.c-rfq-nda {
  // margin: 2% 20px 0 17%;
  display: flex;
  justify-content: center;
  margin-left: 220px;
  .c-rfq-nda--container {
    margin-bottom: 150px;
  }

  .c-rfq-nda--left-block {
    border-right: 1px solid $color-grey;
  }

  .c-rfq-nda--uploaded-nda {
    margin: 24px 0 50px 0;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    padding: 16px 0;
    align-items: center;

    svg {
      cursor: pointer;
    }

    .c-uploaded-file {
      padding-left: 20px;

      & > span {
        display: inline;
        color: #1473e6;
        text-decoration: underline;
      }
    }
  }

  .c-rfq-nda--upload-nda {
    margin: 24px 0 50px 0;
    font-size: 16px;
    color: $color-text-light;
    width: 100%;

    & > div {
      border: 1px solid $color-part-thumbs-border;
      padding: 10px;
      border-radius: 4px;
      align-items: center;
      display: flex;
    }

    & > p {
      font-size: 12px;
      padding: 8px 20px;
    }

    svg {
      color: #7e7e7e;
      font-size: 20px;
      margin: 5px;
    }

    .c-rfq-nda--btn {
      background: transparent;
      color: #3b33ff;
      text-transform: none;
      padding: 0 5px;
      font-size: 16px;
      text-decoration: underline;
    }
  }
}
