.c-tag-options-clearIndicator {
  width: 100%;
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
}

.c-tag-options-container {
  padding-left: 16px;
}

.c-tag-options-description {
  color: #00000099;
}
