.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #101326;
}

.logo {
  height: 42px;
  margin-bottom: 32px;
}

.c-login {
  width: 100%;

  .c-login--field {
    padding-bottom: 16px;
  }

  .c-login--button {
    padding-bottom: 16px;
  }
}
