@import "./how-it-works-modal/how-it-works-modal.scss";

.c-choose-recipients {
  display: flex;
  justify-content: center;
  margin-left: 85px;
  &--title {
    font-weight: 500;
  }
  .c-choose-recipients--subtitle {
    color: $color-text-light;
  }

  .c-choose-recipients--form {
    margin-top: 20px;

    .MuiGrid-container {
      margin: 0;

      .c-choose-recipients-section {
        border: 1px solid $border-color-grey;
        padding: 0;
        border-radius: 4px;
        width: 742px;
        &__title {
          font-weight: 500;
          margin-top: 56px;
          margin-bottom: 12px;
        }

        & > p {
          padding: 16px;
          border-bottom: 1px solid $border-color-grey;
          margin: 0;
          font-size: 20px;
        }

        .c-link-to-video {
          font-size: 12px;
          color: #378ef0;
          font-weight: bold;
          display: flex;
          align-items: center;

          span:first-child {
            font-size: 20px;
            color: #000000de;
            font-weight: normal;
          }

          svg {
            margin: 0 4px 0 6px;
            font-size: 18px;
          }

          u {
            margin-left: 4px;
            cursor: pointer;
          }
        }

        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
          padding: 0 60px 0 12px;
          height: 56px;

          input {
            padding: 0;
          }
        }
      }

      .c-add-emails-section {
        padding: 0;
      }

      .MuiOutlinedInput-root {
        fieldset,
        &.Mui-focused fieldset {
          border: none;
        }
      }
    }

    .c-choose-recipients--grid.MuiGrid-item {
      padding: 0;
      margin: 50px 0;
    }

    .MuiAutocomplete-tag {
      background-color: #eeeeee;
      margin: 0 3px;
    }
  }
}
