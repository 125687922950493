.canvas-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

canvas.canvas-viewer {
  width: 100%;
  height: 100%;
  display: none;
  background: #1a1b25;
}

.snapshot {
  position: absolute;
  color: #d8cdcd;
  right: 5px;
  bottom: 5px;
  display: block;
}

#pdf-viewer {
  height: 100%;
}

.c-main-container {
  flex: 1;
  height: 100%;

  //overflow form only instead of the whole modal
  .c-form {
    height: 100%;
    overflow-y: auto;
  }

  .c-form.MuiGrid-item {
    padding: 24px 0 24px 24px;
  }
}

.c-right-border {
  border-right: 1px solid grey;

  .c-bottom-border {
    flex: 1;
    border-bottom: 1px solid grey;
  }

  .c-add-more-files-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}
