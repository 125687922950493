.c-public-page {
  .c-public-page--part-details {
    border: 1px solid $border-color-secondary;
    background: white;
    color: black;
    list-style: none;
    border-radius: 8px;

    ul {
      margin-left: 0;
    }

    li {
      padding: 12px;
      border-bottom: 1px solid $border-color-secondary;
      display: flex;
      justify-content: space-between;
      margin: 0;

      &:last-child {
        border: none;
      }

      .c-public-page--rfq-name {
        color: $color-text-medium;
        font-size: 14px;
      }

      .c-public-page--quantity {
        color: $color-text-light;
      }

      .c-public-page--reference {
        font-size: 12px;
        color: $color-text-light;
      }

      .c-public-page--part-quantity {
        color: $color-text-light;
        font-size: 12px;
      }
    }
  }
}
