#mouse-over-popover {
  pointer-events: none;

  & .MuiPopover-paper {
    width: 452px;
    min-height: 200px;
    box-shadow: 0px 16px 24px $profile-line-color;
  }
}

.c-seller-info {
  color: black-800;
  font-size: $font-size-root;
  pointer-events: auto;
  padding: 20px;

  .c-seller-info--image-name {
    height: 100%;

    .c-seller-info--seller-img {
      width: 70px;
      height: 70px;
    }

    .c-seller-info--seller-name {
      font-weight: $font-weight-normal;
      font-size: $font-size-18;
    }
  }

  .c-seller-info--details {
    & > div {
      padding: 5px;
    }

    .c-seller-info--site,
    a {
      text-decoration: underline;
      color: black-800;
    }
  }
}

.c-seller-rating {
  .c-seller-rating--rating-container {
    padding: 4px 20px 20px 12px;
    border-bottom: 1px solid $border-color-secondary;

    .MuiRating-root {
      color: $color-primary;
      height: 11px;

      .MuiSvgIcon-root {
        width: 11px;
        height: 11px;
      }
    }
  }

  .c-seller-rating--no-rating {
    padding: 24px 24px;
    border-bottom: 1px solid $border-color-secondary;
  }

  .c-seller-rating--address-container {
    padding: 20px;

    .c-seller-rating--icon {
      color: #c7c7c7;
    }

    .place-icon-width {
      width: 16px;
      height: 16px;
    }

    .account-box-icon-width {
      width: 14px;
      height: 14px;
    }

    .language-icon-width {
      width: 16px;
      height: 16px;
    }

    .c-seller-rating--website-link {
      color: $color-primary;
      pointer-events: all;
    }
  }
}
