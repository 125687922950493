.c-user-onboarding-choose-company {
  .supplier-description {
    color: $color-text-medium;
  }

  .box-radio-button {
    border: 1px solid $border-color-light-grey;
    border-radius: 4px;
    margin-bottom: 40px;
    cursor: pointer;
    width: 440px;
    &.active {
      background: white;
    }
  }

  .radio-button {
    margin-left: 4px;
    margin-bottom: -1px;
    height: 56px;
  }

  .stepper-button-icon {
    width: 10px;
    height: 10px;
  }
}
