.c-notifications-dialog {
  .c-notifications-dialog--box {
    color: $profile-text-color;
    letter-spacing: 0.2px;
  }

  .c-notifications-dialog--closeIcon {
    cursor: pointer;
    opacity: 0.6;
  }

  .c-notifications-dialog--divider {
    border-bottom: 1px solid transparent;
    width: 100%;
    margin-bottom: 20px;
  }

  .c-notifications-dialog--content {
    overflow-y: hidden;
    width: 500px;
    height: 400px;
    margin-left: 8px;
  }

  .c-switches-group--box {
    color: $profile-text-color;
    margin-top: 8px;
    margin-bottom: 26px;
    letter-spacing: 0.01px;
  }
}
