@import "./attachments/attachments.component.scss";
@import "./details/details.component.scss";
@import "./form/form.component.scss";
@import "./tags/tags.scss";
@import "./thumbnail/thumbnail.component.scss";
@import "./upload/upload.component.scss";
@import "./viewer/viewer.component.scss";

.c-part-dialog {
  display: flex;
  align-items: center;

  .MuiAvatar-root {
    width: 20px;
    height: 20px;
    font-size: 15px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

.c-create-part-dailog {
  .MuiPaper-root.MuiDialog-paper {
    height: 100%;
  }

  .c-part-dialog--upload-area,
  .part-dialog-drop-zone-container {
    height: 100%;
  }

  .part-dialog-drop-zone-title {
    margin: 20px 97px 30px;
  }

  .drop-zone-wrapper {
    height: 65%;
  }

  .drop-zone,
  .c-container--dropzone-grid {
    height: 100%;
  }

  .c-part-dialog-content.MuiDialogContent-root {
    padding: 0;
  }
}

.c-upload-po-dialog {
  .MuiPaper-root.MuiDialog-paper {
    height: 72%;
  }
}

.c-part-dialog-drop-zone {
  padding: 0;

  &.MuiDialogContent-root {
    overflow: auto;
  }

  .part-dialog-drop-zone-container {
    width: 100%;

    .box-tile-width {
      width: 100%;
    }
  }
}
