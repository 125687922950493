.drop-zone-wrapper {
  width: 100%;
  margin: 0 auto; // align drop zone in the center
  border-radius: 8px;
  &.border-none {
    border: none;
  }
  &.border-solid {
    border: 1px solid $border-color-black-1f;
  }
  .drop-zone {
    position: relative;
    text-align: center;
    color: black;
    padding: 5px;
    justify-content: center;

    .drop-zone-mask {
      position: absolute;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: none;
      border-radius: 8px;
      margin: 0;
      .drop-text {
        position: relative;
        font-size: 30px;
        color: #eaeaea;
        font-weight: bolder;
      }
    }

    // The ampersand is used to keep nested sass only to a level or two
    // It always refers to the parent selector when nesting.
    // Think of the & as being removed and replaced with the parent selector. Like this:
    // .drop-zone.dropzone-highlight
    &.dropzone-highlight {
      .drop-zone-mask {
        width: 100%;
        height: 100%;
        background-color: white;
        border: 3px dashed #eaeaea;
        justify-content: center;
      }
    }

    .dropzone-icon {
      font-size: emSize(10, 90);
      line-height: 1;
      color: #fff;
      background-size: auto 100%;
      &::before {
        opacity: 0;
      }
    }

    h6 {
      text-align: left;
    }

    .MuiTypography-body1 {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dropzone-title {
      margin: 0;
      font-size: emSize(12, 29);
      line-height: 1.125;
      font-weight: bold;
      text-transform: uppercase;
      color: gray;
    }

    .dropzone-content {
      .upload-icon-ct {
        border: 1px solid #828282;
        display: flex;
        align-items: center;
        border-radius: 50px;
        height: 46px;
        width: 46px;
        justify-content: center;
        margin-top: 6px;
        cursor: pointer;
      }
      .dropzone-text {
        margin-left: 24px;
        line-height: 1.5;
        text-align: left;
        color: $color-text-medium;
        p {
          margin: 0;
        }
      }
      .dropzone-subtitle-text {
        color: $color-text-light;
      }

      a.file-select {
        text-decoration: underline;
        cursor: pointer;
        color: $color-primary;
      }

      a.file-select:hover {
        text-decoration: none;
      }

      .file-upload-instructions {
        font-size: 14px;
        color: grey;
        font-weight: normal;
      }

      .file-upload-browse-btn {
        display: none;
      }
    }

    .dropzone-browse {
      position: relative;
      line-height: normal;
      padding: 0;
      border: 0;
      background: none;
      text-decoration: none;

      &::before {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        content: "";
        width: 100%;
        height: 0;
        margin-top: -2px;
        border-bottom: 1px solid;
        transition: transform 0.15s ease;
      }

      &:hover,
      &:focus,
      &:active {
        &::before {
          transform: scaleX(0);
        }
      }
    }
    .upload-icon {
      border: 1px solid black;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      background: white;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .extensions {
    font-size: 12px;
    margin-top: 12px;
    color: #9e9e9e;
    font-weight: 400;
    text-align: center;
  }

  .secure-msg {
    svg {
      font-size: 13px;
      color: #666666;
      margin-top: -3px;
      margin-right: 8px;
    }
  }

  .c-container {
    width: 400px;
    margin-top: 8px;
  }

  .drop-zone-select-file-btn {
    width: 330px;

    .MuiBox-root {
      justify-content: center;
    }
  }
}

.MuiSnackbarContent-root {
  .MuiSnackbarContent-message {
    max-width: 600px;
  }
}
