@import "./tag-options/tag-options.scss";

.MuiOutlinedInput-adornedEnd-551 {
  border: solid 1px black;
}

.c-rfq-form {
  .c-rfq-form--toolbar {
    margin: 0 68% 0 9%;
  }

  .c-rfq-form--form {
    margin: 2% 10% 0;
  }

  .c-rfq-form--submit {
    margin-top: 50px;
  }

  .c-rfq-form--partQuantity {
    flex-grow: 1px;
  }

  .c-rfq-form--paper {
    padding: 2px;
    text-align: center;
    border: 1px solid grey;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
