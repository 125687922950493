.c-quotation-extra-fields-table {
  &__container {
    flex-direction: column;
    background-color: #f7f7f7;
  }

  &__title {
    padding: 10;
  }

  &__tableWrapper {
    display: flex;
    width: 100%;
    background-color: white;
  }

  &__table {
    position: relative;
    flex-grow: 1;

    & .MuiDataGrid-root .MuiDataGrid-viewport {
      overflow: unset;
    }
  }
}
