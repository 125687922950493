.c-tabs {
  .c-tabs--tab-item {
    color: $color-text-light;

    text-transform: none;
    max-width: max-content;

    &.Mui-selected {
      color: black;
      font-weight: 500;
    }
  }

  .MuiTab-fullWidth {
    width: fit-content;
    max-width: 235px;
    min-width: max-content;
  }

  .MuiTabs-indicator {
    height: 4px;
  }
}
