.c-project {
  .MuiDataGrid-root .MuiDataGrid-columnsContainer {
    border-top: 1px solid rgba(224, 224, 224, 1);
  }

  &__navbar {
    position: relative !important;
    &.MuiAppBar-root {
      padding: 0;
      border-bottom: 1px solid $border-color-secondary;
      border-top: 1px solid $border-color-secondary;
      box-shadow: none;
      & > .MuiToolbar-regular {
        height: 88px;
        padding: 0px 24px;
      }
    }

    &__toolbar {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 80px;
      padding-right: 80px;
      button {
        margin-left: 8px;
      }

      &--part-selection {
        width: 100%;
        height: 100%;
        padding-left: 80px;
        padding-right: 80px;
      }
    }
  }

  &__tabs {
    margin-left: 24px;
    button.MuiTab-root {
      padding: 0;
      margin-right: 47px;
    }
  }

  .c-mousse-popover {
    &__name:hover {
      text-decoration: underline;
    }
  }

  .MuiButton-textPrimary:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
  .MuiButton-textPrimary {
    color: inherit !important;
  }

  &__divider {
    width: auto;
    margin-left: -64px;
    margin-right: -64px;
  }
}

.quotation-column-header {
  font-weight: 400;
  font-size: 11px;
  height: 55px;
  line-height: 1 !important;

  .MuiDataGrid-columnHeaderTitle {
    text-overflow: unset !important;
    overflow: unset !important;
    white-space: break-spaces !important;
  }
}

.MuiAutocomplete-groupLabel {
  top: -8px;
  background-color: #fff;
  padding-left: 30px;
}
