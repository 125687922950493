.c-company-capabilities {
  width: 100%;

  .c-company-capabilities--title {
    text-align: left;
    font-size: 24px;
    letter-spacing: 0.18px;
    color: $color-text-medium;
  }
  .margin-top {
    margin-top: 32px;
  }
  .c-company-capabilities--subtitle {
    margin-top: 8px;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: $color-text-light;
    margin-bottom: 32px;
  }
  .checkbox-color {
    &.MuiCheckbox-colorSecondary.Mui-checked {
      color: $color-primary;
    }
  }
  .c-company-capabilities--text-field {
    margin-top: 24px;
    width: 100%;
  }

  .c-company-capabilities--btn {
    margin-top: 24px;
    position: relative;
    .button-progress {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
      color: $color-primary;
    }
  }

  .c-capability-accordion {
    .c-capability-accordion--type {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.15px;
      color: $color-text-light;
    }
    .c-capability-accordion--badge {
      background-color: $color-primary;
      font-weight: bold;
      font-size: 12px;
      width: 22px;
      height: 22px;
    }
    &.MuiAccordionDetails-root {
      margin-left: 0;
    }
  }
}
