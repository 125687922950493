.c-link.MuiGrid-item {
  margin: 1em;
}

.c-minorLink {
  text-decoration: underline;
  font-family: $font-preferred;
  font-size: $font-size-14;
}

.c-majorText.MuiTypography-body1 {
  color: #1f1f1f;
  text-align: center;
  font-weight: medium;
  font-family: $font-preferred;
  font-size: $font-size-14;
}

.c-majorLink {
  font-family: $font-preferred;
}
