.c-group-switch {
  border: solid #e0e0e0 1px;
  border-radius: 4px;
  padding-right: 10px;

  &.MuiFormControlLabel-root {
    margin-right: 16px;

    &.Mui-disabled {
      cursor: not-allowed;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: black;
  }

  &.c-checkbox-active {
    border: solid black 1px;

    &.Mui-disabled {
      opacity: 0.5;
    }
  }
}

.divider {
  color: #0000001f;
  margin-bottom: 36px;
  width: 100%;
}
