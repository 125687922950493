.c-details-summary-total-fees {
  .c-details-summary-total-fees--upload-content {
    width: 50%;
  }

  .c-details-summary-total-fees--fees-content {
    width: 50%;
    border: 1px solid $color-grey;
  }

  .c-details-summary-total-fees--upload-avatar {
    margin-top: 12px;
    .c-details-summary-total-fees--upload-avatar--width {
      width: 20px;
      height: 20px;
      font-size: 11px;
    }
  }

  .c-details-summary-total-fees--avatar-fees {
    margin-top: 3.5px;

    .c-details-summary-total-fees--avatar-fees--width {
      width: 15px;
      height: 15px;
      font-size: 11px;
      margin-left: 2px;
    }
  }

  .c-details-summary-total-fees--sub-total {
    border-bottom: 1px solid $color-grey;
  }

  .c-download-pdf {
    color: black;
    margin-left: 20px;
    text-decoration-line: underline;
    cursor: pointer;
    color: black;
    opacity: "87%";
  }

  .c-box {
    color: #000;
    opacity: "87%";
  }
}
