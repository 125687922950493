@import "./tag-form/tag-form.scss";

.c-tag-dialog {
  .MuiDialog-paperWidthSm {
    max-width: 472px !important;
  }

  .c-tag-dialog--app-bar {
    background: transparent;
    color: black;
    border-bottom: 1px solid white;
    height: 72px;

    .c-tag-dialog--grid-container {
      padding: 13px 24px;
      overflow: hidden;
    }
  }

  .c-tag-dialog--content {
    width: 424px;
    padding: 24px;

    .c-tag-dialog--content--max-tags {
      margin-bottom: 24px;
    }
  }
}
