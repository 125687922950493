.app-sidebar {
  background-color: $table-header-bg-color;
  // FIXME: Hard coded for now.. This has the same width as the drawer
  //  I will do another pass to fix this.
  //  KB (18/05/20)
  width: 256px;
  height: 100%;

  &__grid {
    border-bottom: 1px solid rgba(189, 189, 189, 0.5);
    height: 80px;
    padding-left: 20px;
  }

  &__logo {
    width: 72px;
    cursor: pointer;
  }

  &__profile {
    height: 120px;
    &:hover {
      background-color: $color-grey-100;
    }
  }

  &__navlink {
    color: black;

    li {
      &:hover {
        background-color: $color-grey-100 !important;
      }
    }

    &:focus {
      background-color: $color-grey-100 !important;
    }

    &:hover {
      text-decoration: none;
    }

    // Give the material icon a default color
    // FIXME: the material UI icon takes primary as color prop value.
    // We should override that one instead
    // KB (18/05/20)
    svg {
      color: $color-grey-svg;
    }

    .company-logo {
      svg {
        color: white;
      }
    }
  }

  &__navlink-active {
    li {
      background-color: $color-grey-100 !important;
      border-left: 4px solid $color-primary !important;
    }
    svg {
      color: black;
    }

    .company-logo {
      svg {
        color: white;
      }
    }
  }

  .menu-item {
    height: 64px;
    border-left: 4px solid white;
  }

  .divider {
    background: #bdbdbd;
    opacity: 0.5;
  }

  .menu-item-text {
    color: #ffffff99;
  }

  .workspace-marketplace-text {
    margin-left: 18px;
  }

  .company-icon-box {
    margin-top: -2px;
    .company-icon {
      font-size: 1em;
      margin-left: 4px;
    }
  }

  .company-text {
    margin-left: 23px;
    margin-right: 8px;
  }

  .c-capability-accordion--badge {
    background-color: $color-primary;
    font-weight: bold;
    font-size: 12px;
    width: 22px;
    height: 22px;
  }

  .company-logo-container {
    .company-logo {
      width: 48px;
      height: 48px;
    }
  }
  .company-name {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}
