.c-how-it-works-modal {
  .c-how-it-works-video--container {
    margin-top: 28px;
    margin-bottom: 24px;
    width: 594px;
  }

  .c-how-it-works-video-dialog--box {
    .c-how-it-works-video-dialog--box--btn {
      width: 100%;
      font-size: 14px;
    }
  }
}
