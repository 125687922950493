// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl

// Predefined Break-points
@mixin for-xs-screen {
  @media (min-width: 320px) {
    @content;
  }
}
@mixin for-sm-screen {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-md-screen {
  @media (min-width: 960px) {
    @content;
  }
}
@mixin for-lg-screen {
  @media (min-width: 1280px) {
    @content;
  }
}
@mixin for-xl-screen {
  @media (min-width: 1920px) {
    @content;
  }
}
