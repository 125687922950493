.c-user-assurance-quality {
  background: $color-background-step;
  height: 100vh;
  overflow: auto;

  .c-user-assurance-quality--divider {
    height: 2px;
    margin-bottom: 40px;
    margin-top: 12px;
  }

  .c-user-assurance-quality--add-icon {
    color: $border-color-secondary;
    width: 48px;
    height: 48px;
    margin-left: 68px;
    margin-top: 30px;
    cursor: pointer;
  }

  .c-user-assurance-quality--add-file-text {
    text-align: center;
    color: $color-text-light;
    margin: 0 25px 0 25px;
  }

  .c-user-assurance-quality--error-message {
    margin-left: 16px;
  }

  .c-user-assurance-quality--reference-description {
    margin-bottom: 24px;
    color: $color-text-light;
  }

  .c-user-assurance-quality--reference1 {
    margin-bottom: 16px;
    color: $color-text-medium;
  }

  .c-user-assurance-quality--reference2 {
    margin-bottom: 16px;
    margin-top: 24px;
    color: $color-text-medium;
  }

  .c-user-assurance-quality--container {
    width: 558px;
    padding-bottom: 48px;
    padding-top: 40px;
  }

  #file[type="file"] {
    display: none;
  }

  .result {
    display: flex;
    flex-wrap: wrap;
  }

  .c-user-assurance-quality--file {
    height: 201px;
    border-radius: 4px;
    object-fit: cover;
    width: 180px;
    margin-bottom: 8px;
    position: relative;

    .MuiIconButton-root:hover {
      background-color: white;
    }
  }

  .c-user-assurance-quality--image {
    width: 180px;
    height: 201px;
    object-fit: cover;
    padding: 30px 0 30px 0;
    border-radius: 4px;
    border: 1px solid #dedede;
  }

  .close-icon {
    color: black;
    background-color: #ffffff;
    box-shadow: #00000033;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    position: absolute;
    margin-top: 18px;
    margin-left: 140px;
  }

  .c-user-assurance-quality--doc {
    color: $color-text-light;
    text-align: center;
    border: 1px solid #dedede;
    height: 201px;
    object-fit: cover;
    border-radius: 4px;
    max-width: 180px;
    padding: 8px;
    word-break: break-all;
  }

  .input-dimension {
    height: 201px;
    border: 1px solid #dedede;
    border-radius: 4px;
    background-color: white;
    max-width: 180px;
    cursor: pointer;
  }

  .MuiInputBase-root {
    &.Mui-focused {
      border: grey;
    }
  }

  .c-user-assurance-quality--field {
    width: 261px;
    height: 56px;
    margin-bottom: 20px;
  }

  .phone-number {
    .MuiOutlinedInput-colorSecondary.Mui-focused
      .MuiOutlinedInput-notchedOutline {
      border-color: black;
    }
    .MuiFormLabel-colorSecondary.Mui-focused {
      color: black;
    }
  }
}
