.c-list-of-tags {
  display: flex;
  flex-wrap: wrap;

  .c-list-of-tags--chip {
    margin: 4px 0 2px 4px;
    max-width: 210px;

    .MuiChip-label {
      padding: 6px;
    }
  }

  .c-list-of-tags--avatar {
    width: 48px;
    height: 30px;
    border-radius: 2px;
    margin-left: 4px;
    margin-top: 4px;
    background: $border-color-secondary;
    font-size: 12px;

    .c-list-of-tags--add-icon {
      width: 10px;
      color: black;
    }
  }
}
