.c-quotation-thread {
  height: 100%;

  .c-quotation-thread--card {
    &.active {
      background-color: #f5f5f5;
    }
  }

  & > div {
    box-shadow: none;
    border-bottom: 1px solid $border-color-secondary;
    border-radius: 0;
  }

  .c-quotation-thread--loading {
    height: 100%;
  }

  .MuiAvatar-circle {
    width: 36px;
    height: 36px;
  }

  .MuiCardContent-root {
    padding: 16px 12px;
  }

  .c-quotation-thread--info {
    margin: 0 12px;

    h6 {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .c-quotation-thread--count {
    .MuiBadge-badge {
      font-size: 14px;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      font-weight: bold;
    }
  }

  .c-quotation-thread--status {
    position: absolute;
    font-size: 14px;
    bottom: -1px;
    right: -4px;
  }

  .c-quotation-thread--last-message {
    padding-left: 50px;
    padding-top: 3px;
  }

  .c-quotation-thread--status-container {
    position: relative;
  }
}

// Empty messages view css

.c-quotation-thread--no-messages {
  margin: 48px;
  text-align: center;

  & > img {
    width: 200px;
    margin-bottom: 26px;
  }
}
