.viewer-component {
  position: relative;
  height: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;

  .manufacturability-assessment-msg {
    position: absolute;
    top: 16px;
    left: 16px;
    max-width: 450px;
    z-index: 1;
    padding: 8px;
    border-radius: 7px;
    display: flex;
    color: white;
    &.status-0 {
      background: #199536;
    }
    &.status-1 {
      background: $color-primary;
    }

    p {
      margin: 0;
    }
  }
  .loading-status {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .snapshot-icon {
    position: absolute;
    z-index: 1;
    color: #ffffff;
    right: 24px;
    top: 20px;
    display: block;
    background: #00000069;
    border-radius: 5px;
    padding: 16px;
    cursor: pointer;

    svg {
      font-size: 24px;
    }
  }

  .fullscreen-icon {
    position: absolute;
    z-index: 1;
    color: #ffffff;
    right: 24px;
    bottom: 20px;
    display: none;
    background: #00000069;
    border-radius: 5px;
    padding: 16px;
    cursor: pointer;

    svg {
      font-size: 24px;
    }
  }

  .view-section {
    position: absolute;
    z-index: 1;
    color: black;
    left: 24px;
    bottom: 40px;
    display: block;
    background: transparent;
    padding: 16px;
    display: none;

    svg {
      font-size: 24px;
    }
  }

  .canvas-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: url("../../../assets/img/3D-viewer-background.jpg");

    .loader-wrapper {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      background: url("../../../assets/img/3D-viewer-background.jpg");
      z-index: 10;
      justify-content: center;
      .loader-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .canvas-wrapper:hover {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  /* Apply a "closed-hand" cursor during drag operation. */
  .canvas-wrapper:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  canvas.viewer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
  }
}
// Hide th xeogl loader
// as it is replaced by
// our own loader
.sk-fading-circle {
  display: none;
}
