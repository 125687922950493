.c-part {
  border: 1px solid transparent;
  border-radius: 5px;

  .c-part--tags-container {
    color: $color-text-light;
    max-width: 98%;
    padding: 8px 12px;
    height: 76px;
    overflow-y: auto;
    overflow-x: hidden;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .c-part--add-tag {
    border-top: 1px solid lightgrey;
  }

  .MuiPaper-root {
    position: relative;
    box-shadow: none;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    min-height: 311px;

    .MuiCardHeader-root {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 8px;
    }

    .MuiCardContent-root {
      padding: 0;
    }

    .MuiCardContent-root {
      h6 {
        font-size: 16px;
        word-break: break-all;
        padding: 8px 12px 0 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    img.part-thumbnail {
      width: 100%;
      height: 160px;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #d3d3d3;
      object-fit: cover;
    }

    .MuiCardHeader-action {
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: space-between;

      .MuiButtonBase-root {
        background-color: white;
        box-shadow: 0px 1px 3px #00000033;
        width: 34px;
        height: 34px;

        &.c-part--checkbox {
          border-radius: 20%;
          margin: 0;
        }
      }
    }
  }

  .c-part--overlay {
    position: absolute;
    bottom: -51%;
    left: 0;
    right: 0;
    cursor: pointer;
    background: white;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
  }

  &.c-part--edit-rfq {
    .MuiCardHeader-action {
      justify-content: flex-end;

      span {
        padding: 5px 12px;
        box-shadow: 0px 1px 3px #00000033;
        background: white;
        border: 1px solid #00000033;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}

// remove red background on checkbox hover and active
.c-part--checkbox {
  &.MuiIconButton-colorPrimary {
    &:hover {
      background-color: transparent;
    }

    &.Mui-checked {
      &:hover {
        background-color: transparent;
      }
    }
  }
}

#c-file-download.MuiLink-underlineHover {
  cursor: pointer;
  text-decoration: underline;
  color: #1c00ff;
}
