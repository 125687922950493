.c-project {
  &__table {
    display: flex;
    height: 100%;

    .c-linear-progress {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 1px;
    }

    &__container {
      position: relative;
      min-height: 500px;
      flex-grow: 1;

      &__row {
        &--status-circle-idle {
          color: $border-color-grey;
        }

        &--status-circle-complete {
          color: $color-primary;
        }

        &--order-status-c,
        &--parts {
          display: "flex";
          flex-direction: "column";
          padding-left: "8px";
          .itb-color {
            color: $color-primary;
          }

          div.MuiBox-root {
            display: flex;
            flex: 1;

            .MuiTypography-root {
              &.order-status-badge {
                background: $color-grey-700;
                border-radius: 4px;
                padding: 2px 8px;
                margin: 5px;
              }
            }
          }
        }
      }

      .not-available {
        color: $color-grey-500;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
}
