@import "./discuss/discussion.scss";
@import "./thread/quotation-thread.scss";
@import "./filter-quotations-by-rfq/filter-quotations-by-rfq.scss";
@import "./content-loader/content-loader.scss";

.c-inbox-drawer--appbar.MuiAppBar-root {
  border-bottom: 1px solid $border-color-secondary;
  box-shadow: none;

  & > .MuiToolbar-regular {
    min-height: 68px;
  }
}
.c-inbox-drawer--feedback {
  text-decoration: underline;

  p {
    font-weight: 500;
    cursor: pointer;
    font-size: 12px;
  }
}

.c-chat-dialog {
  .MuiDialog-paper {
    position: absolute;
    bottom: 0;
    right: calc(85vh - 150px);
    margin: 0;
    max-width: 550px;
    width: 550px;
  }

  &.c-chat-dialog-minimized {
    .MuiDialog-paper {
      bottom: -600px;
    }
  }

  &.c-inbox-close {
    .MuiDialog-paper {
      right: 65px;
    }
  }

  &.c-chat-dialog-minimized.c-inbox-close {
    .MuiDialog-paper {
      bottom: -600px;
      right: 65px;
    }
  }

  .c-chat-dialog--title {
    border-bottom: 1px solid #e0e0e0;
    // height: 112px;
    padding: 0;
  }

  .c-chat-dialog--box {
    border-bottom: 1px solid #e0e0e0;
  }

  .c-chat-dialog--box-rfq-name {
    margin-left: 10px;
    font-weight: normal;

    & > span:first-child {
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
      word-break: break-word;
    }
  }
  .box-cursor-pointer {
    cursor: pointer;
  }
  .c-chat-dialog--box-margin {
    margin: 12px 16px;

    button.MuiButtonBase-root {
      padding: 6px;
    }
  }

  .c-chat-dialog--avatar {
    width: 48px;
    height: 48px;
  }

  .c-chat-dialog--instigator-name {
    margin-left: 8px;

    & > p {
      color: $color-text-medium;
      font-size: 14px;
      font-weight: 500;
    }

    .c-chat-dialog--instigator-status {
      font-size: 12px;
      color: $color-text-light;
      font-weight: normal;

      svg {
        color: #c7c7c7;
        margin-right: 5px;
        font-size: 13px;
      }
    }

    .c-chat-dialog--instigator-title {
      white-space: pre-line;
      color: $color-text-light;
      opacity: 1;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
    }

    .c-chat-dialog--instigator-rate-button {
      width: 195px;
      height: 24px;
      font-size: 12px;
      font-weight: 400;
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}

.c-link-to-video {
  font-size: 12px;
  color: #378ef0;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0;

  svg {
    margin-left: 4px;
    font-size: 18px;
  }

  u {
    margin-left: 4px;
    cursor: pointer;
  }
}
