.c-submit-quotation {
  margin-top: 24px;

  .c-submit-quotation--box {
    margin-top: 48px;
    margin-left: 8px;
  }
  .c-submit-quotation--delay {
    margin-top: 48px;
    background: none !important;
  }
  .c-submit-quotation--avatar {
    height: 20px;
    width: 20px;
    font-size: 14px;
  }

  .c-submit-quotation--comment {
    background-color: white;
  }

  .c-submit-quotation--text-field {
    background-color: white;
    & > p {
      &.MuiFormHelperText-root.Mui-error {
        margin: 10px 0;
        position: absolute;
        top: -35px;
      }
    }
  }

  .c-submit-quotation--offer {
    margin-top: 48px;
  }

  .c-submit-quotation--avatar {
    width: 20px;
    height: 20px;
    font-size: 14px;
    margin-left: 8px;
  }

  .c-submit-quotation--btn-container {
    margin-top: 34px;
  }

  @media screen and (max-width: 1140px) {
    .c-submit-quotation--container {
      flex-direction: row;
      justify-content: center;
    }

    .c-submit-quotation--comment,
    .c-submit-quotation--production-delay,
    .c-submit-quotation--btn {
      width: 100%;
    }
  }
}
