@import "./company-form/company-form.scss";
@import "./company-members/company-members.scss";
@import "./company-validation/company-validation.scss";
@import "./company-capabilities/company-capabilities.scss";

.c-user-company {
  .c-tabs {
    .c-tabs--tab-item {
      max-width: max-content;
    }

    .MuiTab-wrapper {
      display: flex;
      flex-direction: row;
    }

    .c-tabs--badge {
      width: 22px;
      height: 22px;
      margin-left: 8px;
      font-size: 12px;
      background-color: $color-primary;
    }
  }
  .c-company-edit-container {
    background-color: #fafafa;
    margin: 0 -32px;
  }
}
