.c-edit-quotation-dialog {
  .c-edit-quotation-dialog--app-bar {
    background: white;
    height: 70px;
    padding: 20px 24px;
  }

  .c-edit-quotation-dialog--content {
    margin-top: 24px;
    background-color: $color-background-cover;
  }
}
