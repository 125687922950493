$APP_CONTENT_TEXT_COLOR: #ffffff;
$APP_CONTENT_BACKGROUND_COLOR: #ffffff;
$APP_CONTENT_BACKROUND_COLOR_ALT: #101326;
$APP_DRAWER_WIDTH: 256px;

// Set the logo positioning to fixed when the sidebar is visible
.MuiAppBar-root {
  &:not(.nav-hidden) {
    .MuiToolbar-root {
      .c-logo {
        position: fixed;
      }
    }
  }
}

.app-root {
  display: flex;
  width: 100%;
  height: calc(100vh - 250px);

  &.open-nps {
    .MuiDrawer-paper {
      top: 92px;
    }
    margin-top: 24px;
  }

  &.nav-hidden {
    nav {
      display: none !important;
    }
  }

  &:not(.nav-hidden) {
    background-color: $APP_CONTENT_BACKGROUND_COLOR;
    nav {
      .app-drawer {
        .c-logo {
          position: fixed;
        }
      }
    }
    .app-drawer {
      width: $APP_DRAWER_WIDTH;
      flex-shrink: 0;
      .MuiDrawer-paper {
        z-index: 1200 !important;
      }
    }
  }
}

.app-floating-appbar {
  width: 100%;
  border-bottom: solid 1px rgba(158, 158, 158, 0.1);
  background-color: #f5f5f5;
  z-index: 999;
  position: fixed;
}
