.c-details-summary-footer {
  .c-details-summary-footer--title {
    margin-bottom: 10px;
  }

  .c-details-summary-footer--additional-details {
    word-break: break-word;
    white-space: break-spaces;
  }
}
