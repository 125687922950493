.c-subtotal-fees {
  .c-subtotal-fees--container {
    display: flex;
    text-align: center;
    padding-top: 24px;
  }
  .flex-direction-left {
    justify-content: flex-end;
  }
  .c-subtotal-fees--card-upload {
    .MuiCardContent-root {
      padding-top: 0;
    }

    width: 50%;
    background-color: $color-background-cover;
  }

  .c-subtotal-fees--display-flex {
    display: flex;
  }

  .c-subtotal-fees--box {
    margin: 4px;
  }

  .c-subtotal-fees--avatar {
    width: 20px;
    height: 20px;
    font-size: 14px;
  }

  .c-subtotal-fees--circular-progress {
    width: 20%;
  }

  .c-subtotal-fees--grid-margin {
    margin: 10px 0px 0px 0px;
  }

  .c-subtotal-fees--box-margin {
    margin-left: 13px;
  }

  .c-subtotal-fees--card-subtotal {
    width: 50%;
    border: 1px solid $color-grey;
  }

  .c-subtotal-fees--card-subtotal-content {
    padding: 30px;
    height: 50%;
    border-bottom: 1px solid $color-grey;
  }

  .c-subtotal-fees--list {
    width: 100%;
  }

  .c-subtotal-fees--card-fees-content {
    padding: 30px;
    height: 50%;
  }

  .c-subtotal-fees--box-fees {
    margin: 4px;
  }

  .c-subtotal-fees--upload-avatar {
    width: 46px;
    height: 46px;
    border: 1px solid #707070;
    background: none;
    color: black;
  }

  .c-subtotal-fees--upload-background {
    margin-top: -28px;
  }

  .c-subtotal-fees--price-field {
    & > p {
      &.MuiFormHelperText-root.Mui-error {
        width: 210px;
        margin: 10px 0;
        position: absolute;
        top: -35px;
      }
    }
  }

  .MuiListItemSecondaryAction-root {
    cursor: pointer;
  }

  @media screen and (max-width: 1140px) {
    .c-subtotal-fees--container {
      flex-direction: column;
    }

    .c-subtotal-fees--card-subtotal,
    .c-subtotal-fees--card-subtotal-content {
      width: 100%;
      justify-content: center;
    }

    .c-subtotal-fees--card-upload {
      width: 100%;
      justify-content: center;
      margin-bottom: -50px;
    }

    .c-subtotal-fees--card-fees-content,
    .c-subtotal-fees--card-subtotal-content {
      padding-left: 24px;
    }
  }
}

.nda-form-disable {
  pointer-events: none;
  opacity: 0.3;
}

.filepond--root,
.filepond--panel-root {
  background-color: transparent;
}

.filepond--root,
.filepond--root .filepond--drop-label {
  width: 247px;
}

.filepond--credits {
  display: none !important;
}

.filepond--drop-label.filepond--drop-label label {
  margin-bottom: 40px !important;
}
