.c-filter-active-rfq-list {
  margin-top: 16px;

  .quotation-title {
    margin-top: 40px;
  }

  .c-filter-active-rfq-list--trash {
    position: relative;
  }

  .MuiGrid-align-items-xs-center {
    margin-top: -24px;
  }
}
