.c-visibility-detection {
  margin-bottom: 16px;

  .bottom-push {
    width: 100%;
    .dropzone-content {
      margin: 0;
      .dropzone-text {
        width: 75%;
      }
    }
  }
  .c-visibility-detection--cad-files-and-viewer-container {
    @media screen and (max-width: 1280px) {
      flex-direction: column-reverse;
    }
  }
}
