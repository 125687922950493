.c-text-field-input {
  border: 1px solid $border-color-secondary;
  background: #ffffff;
  padding: 6px 0 12px;
  border-radius: 4px;

  &.MuiInputBase-root {
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    .MuiInput-underline:before {
      border-bottom: none !important;
    }
    .MuiInput-underline:after {
      border-bottom: none !important;
    }
  }

  .c-text-input--uploaded-file-section,
  .c-text-field-input--message,
  .c-text-field-input--btn {
    padding: 0 12px;
  }

  .c-text-field-input--btn {
    button {
      color: $color-text-medium;
      background: $border-color-secondary;
      width: auto;
      height: 36px;
      cursor: pointer;

      &:hover {
        background: $color-part-thumbs-arrow;
      }
    }
  }

  .c-text-field-input--send-icon {
    width: 13px;
    height: 13px;
  }

  .c-text-input--upload {
    min-width: auto;
    padding: 10px 16px;

    svg {
      font-size: 16px;
    }
  }

  .c-text-input--uploaded-file-section {
    font-size: 12px;
    border-bottom: 1px solid $border-color-secondary;
    padding: 12px;

    &:first-child {
      padding-top: 6px;
    }

    svg {
      cursor: pointer;
      font-size: 14px;
      color: black;
    }
  }

  .c-text-input--upload-error {
    color: black;
    padding-left: 10px;
    margin: 0;
  }
}
.c-text-field-input--message {
  .MuiInputBase-multiline {
    padding: 6px 0 12px 0;
  }
}

.c-file-loader-section {
  width: 88%;
  margin: 0 10px;
}
