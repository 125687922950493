.c-part-quantity {
  border: 1px solid $color-grey;
  border-radius: 5px;
  display: flex;
  margin: 20px 0;

  h5 {
    word-break: break-all;
    font-size: 20px;
  }

  .c-part-quantity--part-thumbnail {
    margin-right: 15px;
    padding: 0px;

    img.part-thumbnail {
      width: 150px;
      height: 150px;
      border: none;
    }
  }

  .c-part-quantity--icon-container {
    .c-part-quantity--icon {
      width: 50px;
      height: 50px;
      margin-top: 10px;
      background: transparent;
      color: black;
      border: 1px solid $color-grey;
    }
    .c-part-quantity--icon:hover {
      cursor: pointer;
    }
  }

  .c-part-quantity--text-field {
    border: 1px solid $color-grey;
    border-radius: 5px;
    margin: 5px 15px 0px 15px;
  }

  .c-part-quantity--remove-from-list {
    line-height: 5px;
    cursor: pointer;
    text-decoration: underline;
  }
}
