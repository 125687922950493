.c-user-rating {
  background-color: white;
  height: 100vh;
  overflow: auto;

  &--logo {
    margin-top: 24px;
    margin-left: 24px;
    width: 90px;
  }

  &--card {
    padding: 0;
    width: 100%;

    @include for-md-screen {
      padding: 18px 30px;
    }

    &.MuiPaper-elevation1 {
      box-shadow: 0px 1px 3px #00000033;
    }

    .c-loader {
      min-width: 600px;
      min-height: 80vh;
    }
  }

  &--info {
    p {
      font-size: 16px;
      padding-top: 16px;
    }
  }

  &--container {
    legend {
      font-weight: bold;
    }

    p {
      margin: 8px 0;
      color: $color-text-light;
    }

    .c-star-rating {
      margin-bottom: 25px;

      .star {
        font-size: 22px;
        padding-right: 3px;
        color: $color-grey-800;

        &.c-star-filled {
          color: black;
        }
      }
    }

    .c-user-rating-field {
      fieldset {
        border-color: #0000001f;
      }
    }
  }

  &--section.MuiGrid-item {
    background-color: white;
    border-radius: 8px;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .MuiRating-iconFilled {
    color: black;
  }

  .MuiRating-iconEmpty {
    color: $color-grey-800;
  }

  .c-user-rating--btn {
    background: $color-primary;
    color: white;
    font-size: 14px;
    box-shadow: none;

    &.MuiButton-contained:hover {
      background: $color-primary;
    }
  }

  .c-user-rating--success {
    height: 90vh;

    p {
      font-size: 48px;
      margin-bottom: 36px;
    }
  }
}
