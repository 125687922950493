.c-user-onboarding-contract {
  background: $color-background-step;
  height: 100vh;
  overflow: auto;

  .c-user-onboarding-contract--container {
    width: 590px;
    padding-bottom: 48px;
    padding-top: 40px;
  }

  .contract-box-title {
    border-bottom: 2px solid $color-grey-50;
  }

  .checkbox-color {
    &.MuiCheckbox-colorSecondary.Mui-checked {
      color: $color-primary;
    }
  }

  .button-progress-container {
    position: relative;
    .button-progress {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
      color: $color-primary;
    }
  }
}
