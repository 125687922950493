.cVioWK {
  color: rgba(0, 0, 0, 0.5);
}

.c-tour--done-btn {
  padding: 5px 10px;
  border-radius: 4px;
  background: $color-primary;
  cursor: pointer;
  color: white;
  border: none;
}

.reactour__close {
  top: 12px;
  right: 12px;
}

.c-tour-btn {
  background-color: black;
  color: white;
  margin: 0 10px;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 44px;

  &.c-empty-workspace--tour-btn {
    background-color: $color-primary;
  }
}

.reactour__helper--is-open {
  .iDyKfX {
    height: auto;
    width: auto;
    padding: 0 10px;
  }
}
