.c-user-survey {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 8px;

  &__question {
    margin-left: 16px;
    flex-grow: 1;
    color: black;
  }

  &__choices {
    button {
      margin-right: 10px;
    }
  }
}
