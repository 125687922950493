.c-table-list {
  width: 100%;
  padding-top: 27px;

  .MuiTableContainer-root {
    box-shadow: none;

    thead {
      border: 1px solid $border-color-secondary;
      text-transform: uppercase;
    }
  }

  .c-table-list-name {
    width: 350px;
  }

  .c-table-list-quantity {
    width: 210px;
    vertical-align: top;
  }

  .c-table-list-quotation {
    width: 250px;
    vertical-align: top;
  }

  .c-table-list-status {
    width: 210px;
  }

  .c-table-list-primary-color {
    color: $color-primary;
  }
}

.c-banner-container {
  width: 100%;
  height: 84px;
  padding: 0 27px;
  background-color: $modal-banner-background-color;

  .c-banner-container--info-icon {
    width: 37px;
    height: 37px;
  }

  .c-banner-container--title {
    padding: 14px 0;
  }
}

.c-extend-rfq {
  padding: 0 20px;

  .MuiFormControl-root {
    width: 100%;
    margin-top: 5px;

    label {
      color: black;
    }
  }

  .MuiInputBase-root {
    border: 1px solid black;
    border-radius: 4px;
    padding: 10px;
    margin-top: 35px;
  }
}

.c-cancel-rfq {
  padding: 0 27px;

  .MuiAutocomplete-root {
    width: 100%;
    padding-bottom: 10px;
  }

  .c-cancel-rfq--text-field {
    padding-top: 20px;
  }

  .c-cancel-rfq--helper-text {
    padding-bottom: 20px;
  }

  .c-cancel-rfq--action {
    padding-top: 48px;
  }
}

.c-rfq-action.MuiDialogActions-root {
  padding-right: 0;
  padding-top: 48px;

  .c-rfq-submit {
    &.MuiButton-textPrimary {
      background-color: black;
      color: white;
      outline: 0;
    }

    &.Mui-disabled {
      background-color: rgb(229, 229, 229);
    }
  }
}

.c-buyer-quotes--table {
  width: 100%;

  .c-context-menu--button.MuiButtonBase-root {
    font-size: 14px;
    border: 1px solid $border-color-secondary;
    color: $color-grey-700;
    border-radius: 4px;
    padding: 10px 16px;
  }

  // prevent fake rfq click handler
  .c-table-list {
    tr.c-table-list--guidance.MuiTableRow-root[data-tut="reactour__buyerRfq"] {
      pointer-events: none;
    }
  }
}
