.c-shared-cancel-rfq {
  padding: 0 27px;

  .MuiAutocomplete-root {
    width: 100%;
    padding-bottom: 10px;
  }

  &--text-field {
    padding-top: 20px;
  }

  &--helper-text {
    padding-bottom: 20px;
  }

  &--action {
    padding-top: 48px;
  }
}
