.c-part-information {
  padding: 0 10%;
  .c-part-information--color-black {
    color: black;
  }

  .c-part-information--color-white {
    color: white;
  }

  .c-part-information--nda-btn {
    text-transform: none;
    color: $color-grey-700;
    margin: 20px 0 10px 0;
  }
}

.c-part-information--uploaded-nda {
  margin: 30px 0 10px 0;

  .MuiListItemText-root {
    color: $color-primary;
    text-decoration: underline;
    padding-left: 16px;
  }
}
