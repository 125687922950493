.c-project-part-table {
  display: flex;
  height: 100%;

  &__container {
    position: relative;
    flex-grow: 1;
    min-height: 500;

    &-search {
      position: absolute;
      left: 360px;
      top: 7px;
      z-index: 100;
    }
  }
}

.c-parts-table-column-borders {
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    border-right: 1px solid rgba(224, 224, 224, 1);
    &:first-of-type {
      border-left: 1px solid rgba(224, 224, 224, 1);
    }
  }

  .MuiDataGrid-columnsContainer {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
