.c-contracts {
  justify-content: space-between;
  height: 70px;
  align-items: center;

  .c-contract--requestRating {
    color: black;
    text-decoration: underline;

    &:hover {
      background: transparent;
    }
  }

  .MuiTableCell-alignLeft {
    vertical-align: top;
  }
  .c-contracts--tableHead {
    background: #f8f8f8;
    border: 1px solid #e0e0e0;
  }
  .c-contracts-sticky-header {
    border-collapse: collapse;
  }
  .c-contracts--tableCell {
    font-weight: bold;
  }
  .c-contracts--rfqName {
    overflow-wrap: break-word;
    font-size: 16px;
    color: #000000;
  }
  .c-contracts--dateOfContract {
    font-size: 12px;
    color: #00000099;
  }
  .c-contracts--companyName {
    font-size: 12px;
    color: #00000099;
    font-weight: bold;
  }

  .c-contracts--price {
    font-size: 16px;
    color: #000000;
  }
  .c-contract--delay {
    font-size: 12px;
    color: #00000099;
  }
  .c-contract--file {
    .MuiList-root {
      .MuiListItem-gutters {
        padding-right: 0;
        padding-bottom: 0;
        margin: 0;
      }
    }
    vertical-align: middle;
    color: #000000de;
    font-size: 14px;
  }
  .c-contracts--link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .c-slider {
    width: 140px;
    margin: auto;

    .carousel-slider {
      .slider-wrapper {
        width: 75%;
        border: 1px solid $color-part-thumbs-border;
        border-radius: 4px;

        .slide {
          background: rgb(254, 254, 254);
          background-image: linear-gradient(
            rgba(254, 254, 254, 1) 0%,
            rgba(241, 241, 241, 1) 24%,
            rgb(206, 206, 206) 100%
          );

          img {
            height: 88px;
            object-fit: cover;
          }
        }
      }
    }

    .control-arrow {
      opacity: 1;
      color: $color-part-thumbs-arrow;
      background: none !important;
      top: -5px;

      &:before {
        display: none;
      }

      &.control-prev {
        left: -12px;
      }

      &.control-next {
        right: -12px;
      }
    }
  }

  .c-contracts--open-the-quotation {
    color: $color-primary;
    font-size: 14px;
  }
}
.c-loader {
  .c-loader--box {
    width: 20%;
    margin-top: 25%;
  }
}
