@import "../user-assurance-quality/user-assurance-quality.scss";
@import "../user-company-information/user-company-information.scss";
@import "../user-onboarding-contract/user-onboarding-contract.scss";
.c-user-onboarding-stepper {
  background-color: white !important;

  .c-stepper {
    width: 100%;
    height: 96px;
    justify-content: center;

    .c-stepper--container {
      margin-left: 0;
      margin-top: 0;
      height: 100%;
      margin-bottom: 24px;
      justify-content: center;
      border-bottom: 1px solid $color-box-shadow;

      .MuiGrid-grid-xs-8 {
        padding: 0;
        height: 100%;
      }

      .MuiStepper-root {
        height: 100%;
      }
    }

    .MuiStepIcon-root.MuiStepIcon-active,
    .MuiStepIcon-root.MuiStepIcon-completed {
      color: $color-primary;
    }

    .MuiStep-alternativeLabel {
      height: 100%;
      padding-bottom: 10px;
    }
  }

  .stepper-button-icon {
    width: 10px;
    height: 10px;
  }

  .c-helper-link-position {
    color: $color-text-medium;
    position: relative;
    height: 0px;
    top: 10px;
    left: -16px;
    .helper-link {
      font-weight: bold;
      color: $color-text-medium;
      font-size: 14px;
      letter-spacing: 0.8px;
      margin-left: 4px;
      color: $color-text-medium;
    }
  }

  .stepper-button {
    font-size: 14px;
  }

  .format-text {
    word-break: break-word;
    white-space: break-spaces;
  }

  .color-link {
    color: $color-primary;
    cursor: pointer;
  }
}

.auth-root {
  .floating-language-toggle {
    z-index: 1114;
  }

  .floating-help-toggle {
    z-index: 1114;
  }
}
