@import "./details-summary-footer/details-summary-footer.scss";
@import "./details-summary-part-tiles/details-summary-part-tiles.scss";
@import "./details-summary-part-tiles/part-tiles-details/part-tiles-details.scss";
@import "./details-summary-header/details-summary-header.scss";
@import "./details-summary-total-fees/details-summary-total-fees.scss";
@import "./stats-report/stats-report.scss";

.c-app-bar {
  &.MuiAppBar-colorPrimary {
    background: white;
  }
  .c-app-bar--back-button {
    margin-bottom: 25px;
  }
}

.c-details-summary {
  .c-details-summary--divider {
    border: 1px solid $color-grey;
  }

  .c-details-summary--summary {
    padding: 20px;
  }
}
