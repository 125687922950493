.c-tag-form {
  .c-tag-form--btn {
    margin-top: 46px;
  }
}

#c-popover {
  .c-popover--box {
    min-width: 180px;
    min-height: 40px;
    padding: 10px 5px;
  }
}

.MuiAutocomplete-popper {
  #selected-tags-demo-popup {
    li {
      margin-bottom: 0;
    }
  }
}
