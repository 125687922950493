.c-recover-password-form {
  padding: 16px;

  .c-recover-password-form--header {
    padding-bottom: 16px;
  }

  .c-recover-password-form--field {
    padding-bottom: 16px;
  }

  .c-recover-password-form--button {
    padding-bottom: 16px;
  }
}
