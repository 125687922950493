.c-filter-quotations {
  .MuiFormControl-root {
    fieldset {
      border: none;
      border-bottom: 1px solid #e0e0e0;
      border-radius: 0;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, 5px) scale(0.75);
    }
  }
}
