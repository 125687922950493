.c-supplier-rfq-list,
.c-table-list {
  .c-slider {
    width: 140px;
    margin: auto;

    .carousel-slider {
      .slider-wrapper {
        width: 74%;
        border: 1px solid $color-part-thumbs-border;
        border-radius: 4px;

        .slide {
          background: rgb(254, 254, 254);
          background-image: linear-gradient(
            rgba(254, 254, 254, 1) 0%,
            rgba(241, 241, 241, 1) 24%,
            rgb(206, 206, 206) 100%
          );

          img {
            height: 88px;
            object-fit: cover;
          }
        }
      }
    }

    .control-arrow {
      opacity: 1;
      color: $color-part-thumbs-arrow;
      background: none !important;
      top: -5px;

      svg {
        font-size: 38px;
      }

      &:before {
        display: none;
      }

      &.control-prev {
        left: -18px;
      }

      &.control-next {
        right: -18px;
      }
    }
  }

  .c-supplier-rfq-list--table-head {
    background-color: $table-header-bg-color;
    border: 1px solid #e0e0e0;

    th {
      text-transform: uppercase;
      color: black;
      font-weight: $font-weight-normal;
    }
  }

  .c-supplier-rfq-list--quantity {
    min-width: 120px;
  }

  .c-supplier-rfq-list--deadline {
    min-width: 140px;
  }

  .c-rfq-name {
    word-break: break-word;
    .lock-icon {
      height: 17px;
      margin-top: -2px;
      margin-left: -6.5px;
      color: black;
    }
    .lock-icon-grey {
      height: 17px;
      margin-top: -2px;
      margin-left: -6.5px;
      color: $color-grey;
    }
    .lock-open-icon {
      height: 17px;
      margin-top: -2px;
      margin-left: -6.5px;
      color: #2d9d78;
    }
  }

  .c-company-name {
    font-weight: bold;
    font-size: 12px;
    color: $color-text-light;
  }

  .MuiTable-stickyHeader {
    border-collapse: collapse;
  }

  .c-context-menu {
    .c-context-menu--border {
      border: 0px;
      background: none;
    }
  }

  .c-supplier-rfq-list--chip {
    background: black;
    border-radius: 2px;
    color: white;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
    margin-top: 4px;
    margin-right: 8px;
    text-transform: capitalize;
  }

  .c-supplier-rfq-list-hover-buttons {
    .c-supplier-rfq-list-hover-buttons--icon {
      min-width: 24px;
      border-radius: 50%;
      visibility: hidden;
    }
  }
}
