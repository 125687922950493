.c-rfq-general-information {
  display: flex;
  justify-content: center;
  .c-rfq-general-information--form {
    justify-content: center;
    .c-rfq-general-information--form--title {
      margin-bottom: 30px;
    }

    .c-rfq-general-information--left-block {
      &.MuiGrid-spacing-xs-5 {
        & > .MuiGrid-item {
          padding: 12px 40px 12px 20px;
        }
      }
    }

    .c-rfq-general-information--calendar {
      & > div {
        width: 100%;
      }
    }

    .c-rfq-general-information--right-block {
      margin-top: 0;

      &.MuiGrid-spacing-xs-5 {
        & > .MuiGrid-item {
          padding: 15px 20px;
        }
      }
    }

    .c-rfq-general-information--comments {
      textarea {
        padding: 10px;
        word-break: break-word;
        white-space: break-spaces;
      }
    }

    .c-rfq-general-information--btn {
      margin-top: 40px;
      margin-bottom: 100px;
      margin-left: 24px;
    }
  }

  .c-rfq-general-information--calendar {
    margin-bottom: 5px;
  }

  .c-general-information--additional-helper-text {
    margin: 0.25rem 0.875rem 0 0.875rem;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    letter-spacing: 0.03333em;

    &.c-supplier-list-helper-text {
      margin-top: 5px;
    }
  }

  .c-rfq-general-information--tags {
    color: black;
  }
}
