@import "./message/message.scss";
@import "./text-input/text-input.scss";

.c-g4-communiaction-msg {
  background-color: #0087ff0a;
}
.c-discussion {
  width: 100%;
  height: 550px;
  display: flex;
  background-color: #f5f5f5;
  flex-direction: column;

  & > * {
    max-width: 100%;
  }
  & > * + * {
    margin-top: 24px;
  }

  &.MuiDialogContent-root {
    padding: 0px !important;
    background: white;
  }

  .c-discussion--content {
    padding: 8px 0;
    -webkit-overflow-scrolling: touch;
    flex: 1% 1% 0%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .c-discussion--text-input {
    padding: 0 16px 12px 16px;
  }

  .c-discussion--created-date {
    position: relative;

    & > p {
      align-self: center;
      margin: 0;
      color: $color-text-light;
      font-size: 12px;

      &:before,
      &:after {
        content: "";
        width: 40%;
        height: 1px;
        background: $border-color-secondary;
        position: absolute;
        top: 15px;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }

  .c-discussion--closed {
    padding: 31px;
    font-size: 12px;
    border-top: 1px dotted #e0e0e0;
    border-top: 1px dotted #e0e0e0;
    margin: 0;
    position: relative;

    p {
      position: absolute;
      margin: 0;
      left: 1rem;
      right: 1rem;
      bottom: 12px;
      text-align: center;

      svg {
        font-size: 12px;
      }
    }
  }
}
