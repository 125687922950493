#root {
  height: 100%;
  min-height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
}

img,
svg {
  vertical-align: middle;
}

a {
  text-decoration: none;
}

ul,
ol {
  margin-left: 1em;
}

li {
  margin-bottom: 0.5em;
}

// Turn off HTML5 spinner for input fields with type number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  color: $color-primary;
}

.modalcontainer {
  max-height: 400px;
  overflow: scroll;
}

button:focus {
  outline: 0;
}

.c-cursor-pointer {
  cursor: pointer;
}

.c-highlighted-row {
  &--true {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.c-text-underline {
  text-decoration: underline;
}

// override overflow hidden on body when chat dialog opens
body.c-page-scroll {
  overflow-y: auto !important;
}

.c-submit-action-button {
  box-shadow: none !important;
}

.DraftEditor-editorContainer {
  a,
  a:hover {
    color: $color-primary;
  }
}

.c-rotate-infinite {
  animation: c-rotate-kf 0.8s linear infinite;
}

@keyframes c-rotate-kf {
  to {
    transform: rotate(360deg);
  }
}

.c-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-hide-column-separator .MuiDataGrid-columnSeparator {
  visibility: hidden;
}

/* slightly transparent fallback for not supported browsers */
.backdrop-blur {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur {
    background-color: rgba(0, 0, 0, 0.5) !important;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
  }
}

body > iframe[style*="2147483647"] {
  display: none;
}
