.c-render-uploads {
  .c-render-uploads--list-item-text {
    text-decoration: underline;
    cursor: pointer;
    word-break: break-word;

    .list-item-text--colorBlue {
      color: $color-primary;
      word-break: break-word;
      font-size: 14px;
      margin-top: -12px;
      max-width: 250px;
    }

    .list-item-text--colorBlack {
      color: black;
    }

    .c-render-uploads--chip {
      font-size: 14px;
    }
  }
}
