.c-graph {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 350px;
  min-width: 850px;

  .c-graph--empty-view {
    p {
      font-size: 1rem;
      margin-top: 20px;
    }
  }

  .apexcharts-text.apexcharts-xaxis-label {
    tspan:nth-child(2) {
      font-size: 14px;
    }
  }

  .c-comments-section {
    h6,
    i {
      font-size: 12px;
    }

    h6 {
      display: inline;
      font-size: 12px;
    }
  }
}
