.c-validation-company-accepted {
  .check-circle-icon {
    margin-left: 24px;
    color: $color-primary;
  }

  .typography-container {
    margin-top: 4px;
    margin-left: 4px;
  }
}
