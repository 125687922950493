.c-user-onboarding {
  height: 100%;
  min-height: 100vh;
  .c-user-onboarding--img-container {
    .img-content--width {
      max-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .c-user-onboarding--form-container {
    background: #fafafa;
    padding: 68px 0px;

    .form-content {
      width: 440px;

      .form-content--img {
        width: 146px;
        height: 50px;

        .form-content--img--width {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .c-user-onboarding-register-form {
    .username-box-width {
      width: 214px;
    }

    .MuiFormControlLabel-root {
      margin-left: -6px;
    }

    .login-link {
      margin-left: 5px;
      font-weight: bold;
    }

    .stepper-button-icon {
      width: 10px;
      height: 10px;
    }

    .checkbox-color {
      &.MuiCheckbox-colorSecondary.Mui-checked {
        color: $color-primary;
      }
    }
  }
}
