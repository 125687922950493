@import "./graph/graph.scss";

.c-stats-report {
  border: 1px solid #bdbdbd;

  .c-stats-report--panel-header {
    .MuiExpansionPanelSummary-content {
      display: flex;
      align-items: center;
      font-size: 18px;

      & > span {
        margin: 0 15px 0 5px;
        font-size: 1.125rem;
      }
    }
  }

  .c-winner-company {
    color: #9e8b24;
    font-weight: bold;
  }

  .c-my-company {
    color: #3d4888;
    font-weight: bold;
  }
}
