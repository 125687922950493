.c-file-dropzone {
  &__icon {
    border: 1px solid #828282;
    display: flex;
    align-items: center;
    border-radius: 50px;
    height: 46px;
    width: 46px;
    justify-content: center;
    margin-top: 6px;
    cursor: pointer;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    border: dashed 2px lightgrey;
    border-radius: 8px;

    div:nth-child(2) {
      margin-left: 15px;
    }

    &--active {
      border: dashed 2px black;
      justify-content: center;
    }
  }
}
