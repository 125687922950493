.card-info {
  border: 1px solid $color-grey;
  width: 100%;

  .card-info--card-content-name {
    padding: 8px 24px;
  }

  .card-info--card-content-description {
    padding: 16px;
  }

  .card-info-color--text-light {
    color: $color-text-light;
  }

  .card-info-deadline {
    margin-top: 8px;
    margin-bottom: 24px;
    color: $profile-text-color;
  }

  .card-info-details {
    word-break: break-word;
    white-space: break-spaces;
  }

  .disable-button-download-zip {
    pointer-events: none;
    opacity: 0.3;
  }

  @media screen and (max-width: 860px) {
    .card-info--box {
      flex-direction: column;
      align-items: flex-start;
    }

    .c-details-summary-header--quotation-name {
      font-size: 18px;
    }

    .card-info--tag {
      padding-left: 0;
    }
  }
}
