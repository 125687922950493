@import "./buyer-stats-created-by-infos/buyer-stats-created-by-infos.scss";
@import "./buyer-stats-company-infos/buyer-stats-company-infos.scss";

.c-buyer-stats {
  margin-top: 52px;
  width: 352px;
  .MuiCardContent-root {
    padding: 24px !important;
  }
}
