.c-part-tiles-details {
  width: 50%;
  border-right: 1px solid $color-grey;
  word-break: break-word;
  white-space: break-spaces;

  &.MuiCardContent-root {
    padding: 24px;
  }

  .MuiListItem-gutters {
    padding: 0;
  }

  .c-part-tiles-details--name {
    margin-left: 20px;
    word-break: break-word;
  }

  .c-part-tiles-details--quantity {
    margin-left: 10px;
  }

  .c-box {
    color: black;
    opacity: 0.87;
  }
}

.nda-form-disable {
  pointer-events: none;
  opacity: 0.3;
}

@media screen and (max-width: 1140px) {
  .c-part-tiles-details {
    width: 100%;
    border: 0;
    border-bottom: 1px solid $color-grey;

    &.MuiPaper-rounded {
      border-radius: 0 !important;
    }
  }
}
