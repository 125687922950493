.c-question-ask {
  width: 100%;

  .c-question-ask--chip {
    max-width: 100px;
    min-width: 100px;
    background: $color-grey;
    border-radius: 5px;
    color: white;
    margin-bottom: 12px;
  }

  .c-question-ask--avatar {
    pointer-events: all;
    border: 1px solid;
    width: 48px;
    height: 48px;
  }

  .c-question-ask--author {
    color: $color-text-light;
  }

  .c-question-ask--created-at {
    color: $color-text-light;
  }

  .c-question-ask--helper-text {
    color: $unnamed-color;
    word-break: break-word;
    white-space: break-spaces;
  }

  .c-question-ask--company-info {
    margin-top: 24px;
    margin-bottom: 19px;
  }
}
