.c-company-form {
  .text {
    width: 408px;
    height: 56px;
  }

  .close-icon {
    cursor: pointer;
  }

  .avatar {
    width: 74px;
    height: 74px;
  }

  .divider {
    border-bottom: 1px solid #f1f1f1;
    height: 1px;
    width: 100%;
  }

  .upload-picture-container {
    .upload-picture-typography {
      margin-top: 40px;
      .upload-picture-input {
        display: none;
      }
      .upload-picture-link {
        text-transform: "none";
      }
    }
  }

  .submit-button-container {
    .submit-button {
      height: 36px;
      width: 123px;
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }

  // showing postal code in uppercase if user enter in lower case
  .c-company-form--postal-code {
    position: relative;

    input {
      text-transform: uppercase;
    }

    .c-company-form--loader {
      position: absolute;
      right: 20px;
      top: 25px;
    }
  }
}
