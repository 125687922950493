.c-one-tag {
  display: flex;
  flex-wrap: wrap;

  .c-one-tag--chip {
    margin: 4px 0 4px 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .c-one-tag--avatar {
    width: 48px;
    height: 30px;
    margin-left: 4px;
    margin-top: 4px;
    background: $border-color-secondary;
    border-radius: 2px;

    .c-one-tag--add-icon {
      width: 10px;
      color: black;
    }
  }
}
