.c-stepper {
  .MuiPaper-root {
    background: none;
  }

  .c-stepper--container {
    margin-top: 22px;
    width: auto;
    justify-content: center;
  }

  .c-stepper-view--helper-text {
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
  }
}
