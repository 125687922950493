.c-projects  {
  &__title {
    max-width: 430px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.18px;
    color: black;
  }
}
