.c-buyer-stats-company-infos {
  border-bottom: 1px solid $color-grey;

  .c-buyer-stats-company-infos--color-blue {
    width: 22px;
    height: 21px;
    margin-left: 5px;
    color: $color-primary;
  }

  .c-buyer-stats-company-infos--record-icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }

  .c-buyer-stats-company-infos--online {
    margin-top: -10px;
  }

  .color-green {
    color: $color-success;
  }

  .color-grey {
    color: $color-grey;
  }

  .c-buyer-stats-company-infos--logo {
    width: 82px;
    height: 82px;
  }

  .c-buyer-stats-company-infos--span {
    margin-top: 1px;
    margin-left: 4px;
    font-size: 14px;
  }
}
