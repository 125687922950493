.c-buyer-stats-created-by-infos {
  border-bottom: 1px solid $color-grey;

  .c-buyer-stats-created-by-infos--box {
    margin-left: -10px;
  }
  .c-buyer-stats-created-by-infos--icon {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
    color: $color-grey;
  }

  .c-buyer-stats-created-by-infos--text {
    margin-left: 5px;
  }

  .c-buyer-stats-created-by-infos--link {
    color: $color-primary;
    cursor: pointer;
  }
}
