.c-question-ask-validation {
  min-height: 700px;
  max-height: 700px;
  .c-question-ask-validation--img {
    margin-top: 5%;
  }

  .c-question-ask-validation--logo {
    width: 250px;
    height: 250px;
  }

  .c-question-ask-validation--text {
    margin-top: 50px;
  }

  .c-question-ask-validation--btn {
    margin-top: 30px;
    .c-question-ask-validation--btn--background {
      background: black;
    }
  }
}
