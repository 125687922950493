img.part-thumbnail {
  width: 100px;
  height: 100px;
  border: 1px solid $color-grey;
  border-radius: 5px;
  cursor: pointer;
  background: rgb(254, 254, 254);
  background-image: linear-gradient(
    rgba(254, 254, 254, 1) 0%,
    rgba(241, 241, 241, 1) 24%,
    rgb(206, 206, 206) 100%
  );
}
