.c-details-summary-part-tiles {
  .c-details-summary-part-tiles--price {
    border: 1px solid $color-grey;
  }

  .c-details-summary-part-tiles--card {
    width: 50%;
  }

  .c-details-summary-part-tiles--card-content {
    height: 50%;
  }

  .border-content {
    border-bottom: 1px solid $color-grey;
  }

  .c-box {
    color: black;
    opacity: 0.87;
  }

  .c-details-summary-part-tiles--quantity {
    margin-left: -15%;
  }
}
