.c-request-demo {
  background-color: white;

  &--btn.MuiButton-root,
  .c-submit-button {
    background: $color-primary;
    color: white;
    font-size: 14px;
    box-shadow: none;
    height: 56px;
    width: 126px;

    &.MuiButton-contained:hover {
      background: $color-primary;
    }
  }

  h6 {
    line-height: 1.25;
    font-weight: normal;
  }

  .MuiFormLabel-colorSecondary.Mui-focused {
    color: inherit;
  }

  &--success.MuiGrid-container {
    height: 90vh;
    width: 60%;

    p {
      font-size: 28px;
      margin-bottom: 36px;
    }
  }

  .c-user-rating--card {
    padding: 18px;
  }

  iframe {
    border: 1px solid $border-color-grey;
    border-radius: 4px;
  }

  .c-request-demo-form--container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}
