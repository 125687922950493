.c-quotation-dialog {
  .c-quotation-dialog--container {
    display: flex;
    overflow: hidden;
    height: 100%;
  }

  .c-quotation-dialog--app-bar {
    background: white;
    height: 116px;
    z-index: 2000 !important;
    padding: 0px;
  }

  .c-quotation-dialog--no-nda {
    .MuiTabs-root {
      margin-left: -12px;
    }
  }

  .c-quotation-dialog--has-nda {
    .MuiTabs-root {
      padding-left: 15px;
    }
  }

  .c-quotation-dialog--app-bar-content {
    height: 68px;
    width: 100%;
    border-bottom: 1px solid 1px $border-color-secondary;
    padding: 20px 36px 20px 24px;
  }

  .c-quotation-dialog--back-icon {
    margin-top: -3px;
    margin-left: -2px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .c-quotation-dialog--rfq-name {
    font-size: 20px;
    color: black;
  }

  .c-quotation-dialog--content-disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .c-quotation-dialog--content {
    margin-top: 100px;
    width: 100%;
    flex-grow: 1;
    background-color: $color-background-cover;

    .c-quotation-loader {
      height: 80vh;
    }
  }
  .c-show-you-interest-color {
    background-color: $color-primary;
    color: white;
  }
  .c-show-you-interest-color:hover {
    background-color: $color-primary;
    border: none;
  }
  .c-show-you-interest {
    padding: 10px;
    height: 36px;
    border: none;

    .c-show-you-interest--icon {
      width: 13px;
      height: 13px;
      margin-top: -3px;
      margin-right: 8px;
    }
    .text-color {
      color: white;
    }
    .c-show-you-interest--text {
      text-transform: none;
    }
  }

  .c-quotation-dialog--drawer {
    width: 352px;
    flex-shrink: 0;
  }

  @media screen and (max-width: 1140px) {
    .c-quotation-dialog--back-icon {
      width: 20px;
      height: 20px;
    }

    .c-quotation-dialog--rfq-name {
      font-size: 16px;
    }

    .c-show-you-interest {
      .c-show-you-interest--icon {
        margin-top: -2px;
      }

      .c-show-you-interest--text {
        font-size: 12px;
        text-transform: none;
      }
    }

    .c-quotation-dialog--ask-question {
      .MuiButton-label {
        font-size: 12px;
      }
    }
  }

  .c-ask-question {
    color: black;
    background: $border-color-secondary !important;
    border: none !important;
    align-items: center;

    .c-ask-question--icon {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }
  }
}
