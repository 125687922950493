.c-company-members {
  .divider {
    border-bottom: 1px solid $color-text-light;
    height: 3px;
  }

  .avatar {
    width: 74px;
    height: 74px;
  }

  .c-company-members--info {
    margin-top: 24px;
  }
}
