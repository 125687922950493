.c-no-tag {
  margin: 4px;

  .c-no-tag--btn {
    background: $border-color-secondary;
    padding: 8px 22px;
  }
  .c-no-tag--lowerCase {
    text-transform: initial;
    font-size: 12px;
  }
}
