.nps-container {
  .nps-container--content {
    justify-content: center;
    align-items: center;
  }

  .nps-container--description {
    text-align: center;
    color: $color-text-medium;
    font-size: 14px;
  }

  .nps-container--rating {
    text-align: center;

    @include for-md-screen {
      margin-top: 14px;
    }

    .MuiFormControlLabel-labelPlacementTop {
      margin-left: -16px;
    }
  }

  .MuiRadio-root {
    margin-top: -8px;
  }

  .like-dosage {
    justify-content: space-between;
    margin-left: -16px;

    .like-dosage--dislike {
      margin-top: -16px;
      color: $color-text-light;
    }

    .like-dosage--like {
      margin-top: -16px;
      color: $color-text-light;
    }
  }

  .nps-container--close {
    color: #000000;
  }
  .nps-container--icon {
    margin-top: 16px;
  }
}
