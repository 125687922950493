.ag-theme-axya {
  --ag-material-primary-color: #2682fd;
  --ag-material-accent-color: #0a2344;
  --ag-foreground-color: rgba(0, 0, 0, 0.87);
  --ag-secondary-foreground-color: rgba(0, 0, 0, 0.54);
  --ag-disabled-foreground-color: rgba(0, 0, 0, 0.38);
  --ag-background-color: #fff;
  --ag-header-background-color: ##fafafa;
  --ag-tooltip-background-color: #fafafa;
  --ag-subheader-background-color: #eee;
  --ag-subheader-toolbar-background-color: rgba(238, 238, 238, 0.5);
  --ag-header-cell-hover-background-color: #fff;
  --ag-chip-background-color: #e2e2e2;
  --ag-range-selection-background-color: rgba(122, 134, 203, 0.1);
  --ag-range-selection-background-color-2: rgba(122, 134, 203, 0.19);
  --ag-range-selection-background-color-3: rgba(122, 134, 203, 0.27);
  --ag-range-selection-background-color-4: rgba(122, 134, 203, 0.34);
  --ag-range-selection-highlight-color: #fce4ec;
  --ag-row-hover-color: #fafafa;
  --ag-column-hover-color: #fafafa;
  --ag-control-panel-background-color: #fafafa;
  --ag-selected-row-background-color: rgba(33, 150, 243, 0.3);
  --ag-checkbox-unchecked-color: #333;
  --ag-value-change-value-highlight-background-color: #00acc1;
  --ag-side-button-selected-background-color: transparent;
  --ag-advanced-filter-join-pill-color: #f08e8d;
  --ag-advanced-filter-column-pill-color: #a6e194;
  --ag-advanced-filter-option-pill-color: #f3c08b;
  --ag-advanced-filter-value-pill-color: #85c0e4;
  --ag-range-selection-border-color: var(--ag-material-primary-color);
  --ag-checkbox-checked-color: var(--ag-material-accent-color);
  --ag-borders: none;
  --ag-borders-critical: solid 1px;
  --ag-border-color: #e2e2e2;
  --ag-grid-size: 8px;
  --ag-icon-size: 18px;
  --ag-header-height: calc(var(--ag-grid-size) * 7);
  --ag-row-height: calc(var(--ag-grid-size) * 6);
  --ag-cell-horizontal-padding: 0;
  --ag-list-item-height: calc(var(--ag-grid-size) * 4);
  --ag-row-group-indent-size: calc(
    var(--ag-grid-size) * 3 + var(--ag-icon-size)
  );
  --ag-filter-tool-panel-sub-level-row-height: calc(var(--ag-grid-size) * 4);
  --ag-checkbox-border-radius: 2px;
  --ag-toggle-button-switch-border-width: 2px;
  --ag-toggle-button-height: var(--ag-icon-size);
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 2);
  --ag-widget-vertical-spacing: calc(var(--ag-grid-size) * 1.75);
  --ag-font-family: "Roboto", sans-serif;
  --ag-font-size: 13px;
  --ag-icon-font-family: agGridMaterial;
  --ag-selected-tab-underline-color: var(--ag-material-primary-color);
  --ag-selected-tab-underline-width: 2px;
  --ag-input-focus-border-color: var(--ag-material-primary-color);
  --ag-input-focus-box-shadow: 0 0 0 5px rgba(32, 33, 36, 0.122);
  --ag-card-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --ag-card-radius: 2px;
  --ag-invalid-color: #e02525;
}

.ag-theme-axya .ag-filter-toolpanel-header,
.ag-theme-axya .ag-filter-toolpanel-search,
.ag-theme-axya .ag-status-bar,
.ag-theme-axya .ag-header-row,
.ag-theme-axya .ag-panel-title-bar-title,
.ag-theme-axya .ag-multi-filter-group-title-bar {
  font-size: calc(var(--ag-font-size) - 1px);
  color: #000;
}
.ag-theme-axya .ag-tab {
  height: calc(var(--ag-grid-size) * 4.5);
  flex: 1 1 auto;
}
.ag-theme-axya .ag-tabs-header,
.ag-theme-axya .ag-column-drop-horizontal {
  background-color: var(--ag-subheader-background-color);
}
.ag-theme-axya .ag-tabs-body {
  padding: calc(var(--ag-grid-size) * 0.5) 0;
}
.ag-theme-axya .ag-tabs-body .ag-menu-list {
  padding-top: 0;
  padding-bottom: 0;
}
.ag-theme-axya .ag-header-cell,
.ag-theme-axya .ag-header-group-cell {
  transition: background-color 0.5s;
}
.ag-theme-axya .ag-row-last:not(.ag-row-first) .ag-cell-inline-editing {
  bottom: 0;
}
.ag-theme-axya .ag-cell-inline-editing {
  padding: var(--ag-grid-size);
  height: calc(var(--ag-row-height) + var(--ag-grid-size) * 3);
  border-color: var(--ag-border-color) !important;
}

.ag-theme-axya .ag-center-cols-clipper {
  background: rgb(250, 250, 250);
}

.ag-theme-axya .ag-pinned-left-header {
  z-index: 1;
}

.ag-header-viewport {
  background-color: rgb(250, 250, 250);
}

.ag-header {
  border-bottom: none;
}

.ag-row {
  border-bottom: none;
}

.ag-cell-last-left-pinned {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.ag-theme-axya .ag-pinned-left-cols-container {
  z-index: 1;
  height: 100% !important;
  background-color: rgb(250, 250, 250);
}

.ag-theme-axya .ag-cell-not-inline-editing {
  border: none;
}
.ag-theme-axya .ag-has-focus .ag-cell-inline-editing {
  border-color: var(--ag-input-focus-border-color) !important;
}
.ag-theme-axya .ag-column-drop-vertical {
  border-bottom: solid 1px;
  border-bottom-color: var(--ag-border-color);
  padding-top: var(--ag-grid-size);
}
.ag-theme-axya .ag-column-drop-vertical.ag-last-column-drop {
  border-bottom: none;
}
.ag-theme-axya .ag-column-drop-vertical-cell {
  margin-left: 0;
}
.ag-theme-axya .ag-column-drop-vertical-empty-message {
  font-size: calc(var(--ag-font-size) - 1px);
  font-weight: 600;
  color: var(--ag-disabled-foreground-color);
}
.ag-theme-axya .ag-ltr .ag-column-drop-vertical-empty-message {
  padding-left: calc(var(--ag-icon-size) + var(--ag-grid-size) * 2);
  padding-right: var(--ag-grid-size);
}

.ag-theme-axya .ag-rtl .ag-column-drop-vertical-empty-message {
  padding-right: calc(var(--ag-icon-size) + var(--ag-grid-size) * 2);
  padding-left: var(--ag-grid-size);
}

.ag-theme-axya .ag-status-bar {
  border: solid 1px;
  border-color: var(--ag-border-color);
}
.ag-theme-axya .ag-column-panel-column-select {
  border-top: solid 1px;
  border-top-color: var(--ag-border-color);
}
.ag-theme-axya .ag-column-select,
.ag-theme-axya .ag-column-select-header {
  border-bottom: solid 1px;
  border-bottom-color: var(--ag-border-color);
}
.ag-theme-axya .ag-column-select-header {
  height: var(--ag-header-height);
}
.ag-theme-axya .ag-group-title-bar {
  padding: calc(var(--ag-grid-size) * 0.75) var(--ag-grid-size);
}
.ag-theme-axya .ag-charts-format-sub-level-group-title-bar {
  padding: calc(var(--ag-grid-size) * 0.5) var(--ag-grid-size);
}
.ag-theme-axya .ag-chart-data-section,
.ag-theme-axya .ag-chart-format-section {
  padding-bottom: calc(var(--ag-grid-size) * 0.5);
}
.ag-theme-axya .ag-chart-menu-close {
  background: var(--ag-background-color);
}
.ag-theme-axya .ag-chart-menu-close .ag-icon {
  background: var(--ag-subheader-background-color);
}
.ag-theme-axya input[class^="ag-"]:not([type]),
.ag-theme-axya input[class^="ag-"][type="text"],
.ag-theme-axya input[class^="ag-"][type="number"],
.ag-theme-axya input[class^="ag-"][type="tel"],
.ag-theme-axya input[class^="ag-"][type="date"],
.ag-theme-axya input[class^="ag-"][type="datetime-local"],
.ag-theme-axya textarea[class^="ag-"] {
  background: transparent;
  color: var(--ag-foreground-color);
  font-family: inherit;
  font-size: inherit;
  height: calc(var(--ag-grid-size) * 5);
  padding-bottom: var(--ag-grid-size);
  border-width: 0;
  border-bottom: 2px solid;
  border-bottom-color: var(--ag-border-color);
}
.ag-theme-axya input[class^="ag-"]:not([type]):focus,
.ag-theme-axya input[class^="ag-"][type="text"]:focus,
.ag-theme-axya input[class^="ag-"][type="number"]:focus,
.ag-theme-axya input[class^="ag-"][type="tel"]:focus,
.ag-theme-axya input[class^="ag-"][type="date"]:focus,
.ag-theme-axya input[class^="ag-"][type="datetime-local"]:focus,
.ag-theme-axya textarea[class^="ag-"]:focus {
  border-bottom: 2px solid;
  border-bottom-color: var(--ag-material-primary-color);
  outline: none;
  box-shadow: none;
}
.ag-theme-axya input[class^="ag-"]:not([type])::placeholder,
.ag-theme-axya input[class^="ag-"][type="text"]::placeholder,
.ag-theme-axya input[class^="ag-"][type="number"]::placeholder,
.ag-theme-axya input[class^="ag-"][type="tel"]::placeholder,
.ag-theme-axya input[class^="ag-"][type="date"]::placeholder,
.ag-theme-axya input[class^="ag-"][type="datetime-local"]::placeholder,
.ag-theme-axya textarea[class^="ag-"]::placeholder {
  color: var(--ag-disabled-foreground-color);
}
.ag-theme-axya input[class^="ag-"]:not([type]):disabled,
.ag-theme-axya input[class^="ag-"][type="text"]:disabled,
.ag-theme-axya input[class^="ag-"][type="number"]:disabled,
.ag-theme-axya input[class^="ag-"][type="tel"]:disabled,
.ag-theme-axya input[class^="ag-"][type="date"]:disabled,
.ag-theme-axya input[class^="ag-"][type="datetime-local"]:disabled,
.ag-theme-axya textarea[class^="ag-"]:disabled {
  border-bottom: 1px solid;
  border-bottom-color: var(--ag-border-color);
}
.ag-theme-axya input[class^="ag-"]:not([type]):invalid,
.ag-theme-axya input[class^="ag-"][type="text"]:invalid,
.ag-theme-axya input[class^="ag-"][type="number"]:invalid,
.ag-theme-axya input[class^="ag-"][type="tel"]:invalid,
.ag-theme-axya input[class^="ag-"][type="date"]:invalid,
.ag-theme-axya input[class^="ag-"][type="datetime-local"]:invalid,
.ag-theme-axya textarea[class^="ag-"]:invalid {
  border-width: 0;
  border-bottom: 1px solid;
  border-bottom-color: var(--ag-invalid-color);
  color: var(--ag-invalid-color);
}
.ag-theme-axya .ag-standard-button {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: var(--ag-material-primary-color);
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
.ag-theme-axya .ag-standard-button:disabled {
  color: var(--ag-disabled-foreground-color);
  background-color: var(--ag-input-disabled-background-color);
  border-color: var(--ag-input-disabled-border-color);
}
.ag-theme-axya.ag-dnd-ghost {
  font-size: calc(var(--ag-font-size) - 1px);
  font-weight: 600;
}
.ag-theme-axya .ag-filter-toolpanel-header {
  height: calc(var(--ag-grid-size) * 4);
}
.ag-theme-axya .ag-filter-toolpanel-group-level-0-header {
  height: calc(var(--ag-grid-size) * 7);
}
.ag-theme-axya .ag-ltr .ag-filter-apply-panel-button,
.ag-theme-axya .ag-ltr .ag-advanced-filter-apply-button,
.ag-theme-axya .ag-ltr .ag-advanced-filter-builder-button {
  margin-left: var(--ag-grid-size);
}

.ag-theme-axya .ag-rtl .ag-filter-apply-panel-button,
.ag-theme-axya .ag-rtl .ag-advanced-filter-apply-button,
.ag-theme-axya .ag-rtl .ag-advanced-filter-builder-button {
  margin-right: var(--ag-grid-size);
}

.ag-theme-axya .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-axya .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-axya .ag-layout-print .ag-center-cols-viewport,
.ag-theme-axya .ag-layout-print .ag-center-cols-container {
  min-height: 150px;
}
.ag-theme-axya .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 60px;
}
.ag-theme-axya .ag-picker-field-wrapper:focus-within {
  box-shadow: 0 0 0 1px var(--ag-material-primary-color);
}
.ag-theme-axya .ag-rich-select-list {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
}
.ag-theme-axya .ag-advanced-filter-builder-button-label {
  text-transform: uppercase;
}
