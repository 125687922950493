.c-pay-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100%;
  &__body {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    width: 550px;
  }
}
