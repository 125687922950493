.auth-root {
  .auth {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100vh;
    background: linear-gradient(90deg, #051745 0%, #397bf0 100%);
  }

  .content {
    background-color: white;
    border-radius: 24px;
    padding: 48px;

    &--small {
      max-width: 488px;
    }

    &--large {
      max-width: 976px;
    }
  }

  .logo {
    height: 42px;
    margin-bottom: 32px;
  }

  .dev-accounts {
    position: absolute;
    left: 50%;
    color: #7a7c88;
    bottom: 40px;
    text-align: center;
    .wrapper {
      position: relative;
      left: -50%;
    }
  }

  .floating-language-toggle {
    position: absolute;
    top: 20px;
    right: 10px;

    .MuiButtonBase-root {
      padding: 5px;
    }
  }

  .floating-help-toggle {
    position: absolute;
    top: 20px;
    right: 75px;

    .MuiButtonBase-root {
      padding: 5px;
    }
  }
}
