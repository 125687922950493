.c-validation-company-waiting {
  .watch-later-icon {
    margin-left: 24px;
  }

  .typography-container {
    margin-top: 4px;
    margin-left: 4px;
  }
}
